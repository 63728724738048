import CryptoJS from 'crypto-js';
import axios from 'axios'
import * as XLSX from 'xlsx';

const key = '188';

export const AXIOS_URL = 'https://api.clsp.folome.com.cn';

export function getWhichPageCookie() {
    var name = "whichpage=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function setWhichPageCookie(value) {
    // 设置cookie的过期时间为7天
    var days = 3650;
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "expires=" + date.toUTCString();

    // 设置cookie
    document.cookie = "whichpage=" + value + ";" + expires + ";path=/";
}

export function logout(){
    axios.post(AXIOS_URL+'/common/auth/logout')
    .then(() => {
        sessionStorage.clear();
        location.reload();
    })
    .catch(() => {
        // 处理错误
        // ElMessage.error({
        //     message: `密码更改失败，${err.response.data.msg}`,
        // })
    });
}

export function getCredentials() {
    const credentialsString = sessionStorage.getItem('credentials');
    if (credentialsString) {
        const credentials = JSON.parse(credentialsString);
        // 检查是否过期
        if (new Date().getTime() < credentials.expiryTime) {
            // 检查用户名是否存在
            if ('username' in credentials) {
                return {
                    urSession: credentials
                };
            } else {
                // 用户名不存在，返回 null
                return null;
            }
        } else {
            // 过期了，删除保存的凭据
            sessionStorage.removeItem('credentials');
        }
    }
    return null;
}

export function encrypt(text) {
    const ciphertext = CryptoJS.AES.encrypt(text, key).toString();
    return ciphertext;
}

export function decrypt(ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
}


export function tranfer2Sheet(arraybuffer,sheetNumber) {
    var data = new Uint8Array(arraybuffer);
    var arr = new Array();
    for (var i = 0; i != data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
    }
    var bstr = arr.join("");
    var workbook = XLSX.read(bstr, { type: "binary" });
    var sheetName = workbook.SheetNames[sheetNumber];
    var sheet = workbook.Sheets[sheetName];
    return sheet;
}


export function sheet2Array(sheet, standard, startRow) {
    // var rowNum = 1;
    var resultList = [];
    var total = sheet["!ref"];
    var startEnd = total.split(":");
    var start = startEnd[0].replace(/[^0-9]/ig, "");
    var end = startEnd[1].replace(/[^0-9]/ig, "");
    for (var rowNum = start; rowNum <= end; rowNum++) {
        if (rowNum >= startRow) {
            var rowObj = {};
            Object.keys(standard).forEach((key) => {
                let column = standard[key];
                let value = sheet[column + "" + rowNum];
                //去掉单元格中的空格和回车
                rowObj[key] = (value && value.w ? value.w : "").replace(/ +/g, "").replace(/[\r\n]/g, "");
            })
            resultList.push(rowObj)
        }
    }
    return resultList;
}