<template>
  <div :style="{pointerEvents:isPointer?'all':'none'}">
    <editor-content :editor="editor" class="editor"/>
  </div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import { MathExtension } from "@aarkue/tiptap-math-extension";
import "katex/dist/katex.min.css";

export default {
    components: {
        EditorContent,
    },
    props:['editorText','isPointer'],
    beforeUnmount() {
        this.editor.destroy()
    },
    data() {
        return {
            editor: null
        };
    },
    watch: {
        editorText: {
            immediate: true, // 立即执行
            handler(newVal) {
                if(!newVal)return
                if(this.editor){
                    this.editor.commands.setContent(JSON.parse(newVal));
                }
            }
        },
    },
    methods: {
        
    },
    mounted() {
        this.editor = new Editor({
        extensions: [
            StarterKit,
            MathExtension.configure({ evaluation: true, katexOptions: { macros: { "\\B": "\\mathbb{B}" } }, delimiters: "dollar", readOnly: true }),
        ],
        // editable: false, // 设置为不可编辑
        });
        this.editor.commands.setContent(JSON.parse(this.editorText));
    }
};
</script>

<style scoped>
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px 15px 50px 15px;
    height: auto;
}
.messageBox{
    margin: 25px 30px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 15px;
}
.searchBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    margin-bottom: 5px;
    box-sizing: border-box;
    overflow: hidden;
    border: #e72b47 1px solid;
    border-radius: 30px;
    background-color: #ffecec;
}
.searchBox p{
    font-size: 13px;
    color: #e72b47;
    font-weight: 600;
    padding-left: 20px;
}
.searchBoxdiv{
    background-color: #e72b47;
    height: 60px;
    width: 55px;
    text-align: center;
    line-height: 60px;
    font-size: 13px;
    color: #ffecec;
    cursor: pointer;
}
</style>