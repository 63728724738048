<template>
    <div class="common-layout">
        <el-container>
            <el-header class="top-bar">
                <div>
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        placement="bottom"
                        raw-content
                    >
                        <template #content>
                            <div style="padding: 15px 5px;">
                                <p class="logoutBtn" @click="logout()">退出登录</p><br>
                                <p class="logoutBtn" @click="showMessage(0)">编辑信息</p>
                            </div>
                        </template>
                        <p>您好，{{ studentName }}<ArrowDown style="height: 15px; padding-left: 5px;"/></p>
                    </el-tooltip>
                </div>
            </el-header>
            <el-container class="body-div">
                <el-aside class="body-left" style="position: relative; overflow: visible;">
                    <div style="position: absolute; top: 0; right: 0; height: 48px; transform: translateY(-100%); display: flex; align-items: center;">
                        <img src="../assets/cuzLogo.png" style="height: 40px;">
                    </div>
                    <div class="leftBtnBox">
                        <div @click="showMessage(1)" class="classCenter">用户管理<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div>
                        <div @click="showMessage(2)" class="classCenter">班级管理<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div>
                        <div @click="showMessage(3)" class="classCenter">课程管理<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div>
                        <div @click="showMessage(4)" class="classCenter">题库管理<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div>
                        <div @click="showMessage(5)" class="classCenter">知识点管理<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div>
                        <div @click="showMessage(6)" class="classCenter">试卷管理<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div>
                        <!-- <div @click="showMessage(7)" class="classCenter">考试管理<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div> -->
                    </div>
                </el-aside>
                <el-main class="body-right">
                    <AdminMessage :style="{'display': isdisplay[0]}"/>
                    <AdminUser :style="{'display': isdisplay[1]}" :userData="userData" :userTotal="userTotal"/>
                    <AdminClass :style="{'display': isdisplay[2]}" :classData="classData" :classTotal="classTotal"/>
                    <AdminCourse :style="{'display': isdisplay[3]}" :courseData="courseData" :courseTotal="courseTotal"/>
                    <AdminTopic :style="{'display': isdisplay[4]}" :topicData="topicData" :topicTotal="topicTotal"/>
                    <AdminKnowPoint :style="{'display': isdisplay[5]}" :knowData="knowData"/>
                    <AdminPaper :style="{'display': isdisplay[6]}" :paperData="paperData" :paperTotal="paperTotal"/>
                    <!-- <AdminExam :style="{'display': isdisplay[7]}" :examData="examData"/> -->
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>


<script>
import { ElMessage } from 'element-plus'
import { logout, getCredentials, AXIOS_URL, setWhichPageCookie, getWhichPageCookie } from '../constants.js'
import AdminMessage from '../components/AdminMessage.vue'
import AdminClass from '../components/AdminClass.vue'
import AdminCourse from '../components/AdminCourse.vue'
// import AdminExam from '../components/AdminExam.vue'
import AdminKnowPoint from '../components/AdminKnowPoint.vue'
import AdminPaper from '../components/AdminPaper.vue'
import AdminTopic from '../components/AdminTopic.vue'
import AdminUser from '../components/AdminUser.vue'
import axios from 'axios'

export default {
    data(){
        return{
            studentName: '同学',
            // 课程、个人信息
            isdisplay: ['black','none','none','none','none','none','none','none'],
            data:[],
            userData:[],
            userTotal:0,
            classData:[],
            classTotal:0,
            topicData:[],
            topicTotal:0,
            knowData:[],
            examData:[],
            courseTotal:0,
            courseData:[],
            paperTotal:0,
            paperData:[],
        }
    },
    mounted(){
        this.studentName = getCredentials().urSession.name
        this.showMessage(getWhichPageCookie()===''?0:getWhichPageCookie())
    },
    methods:{
        logout(){
            logout();
        },
        showMessage(numo){
            const num = parseInt(numo)
            setWhichPageCookie(num)
            if(num===0)this.showClassList=false
            this.isdisplay = this.isdisplay.map(() => {
                return 'none'; 
            });
            this.isdisplay[num] = 'block'
            if(num===1){
                axios.post(AXIOS_URL+'/admin/user/pageSearch',{page:1,size:10,name:null,username:null,sex:null,role:null})
                .then(response => {
                    // 处理成功响应
                    this.userTotal = response.data.data.total
                    this.userData = response.data.data.records
                    var unum = 2
                    while(unum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/user/pageSearch',{page:unum,size:10,name:null,username:null,sex:null,role:null})
                        .then(response => {
                            this.userData = response.data.data.records.concat(this.userData)
                        })
                        unum+=1
                    }
                    // console.log(response);
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
            }else if(num===2){
                axios.post(AXIOS_URL+'/admin/class/pageSearch',{page:1,size:10,name:null})
                .then(response => {
                    // 处理成功响应
                    this.classTotal = response.data.data.total
                    this.classData = response.data.data.records
                    var tnum = 2
                    while(tnum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/class/pageSearch',{page:tnum,size:10,name:null})
                        .then(response => {
                            this.classData = this.classData.concat(response.data.data.records)
                        })
                        tnum+=1
                    }
                })
                .catch(() => {
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
            }else if(num===3){
                axios.post(AXIOS_URL+'/admin/course/pageSearch',{page:1,size:10,name:null})
                .then(response => {
                    // 处理成功响应
                    // console.log(response);
                    this.courseTotal = response.data.data.total
                    this.courseData = response.data.data.records
                    var conum = 2
                    while(conum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/course/pageSearch',{page:conum,size:10,name:null})
                        .then(response => {
                            this.courseData = this.courseData.concat(response.data.data.records)
                        })
                        conum+=1
                    }
                })
                .catch(() => {
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
            }else if(num===4){
                axios.post(AXIOS_URL+'/admin/question/pageSearch',{page:1,size:10,title:null,score:null,difficulty:null,questionType:null,knowledge:null,changed:null})
                .then(response => {
                    // 处理成功响应
                    this.topicTotal = response.data.data.total
                    this.topicData = response.data.data.records
                    var tnum = 2
                    while(tnum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/question/pageSearch',{page:tnum,size:10,title:null,score:null,difficulty:null,questionType:null,knowledge:null,changed:null})
                        .then(res => {
                            this.topicData = this.topicData.concat(res.data.data.records)
                        })
                        tnum+=1
                    }
                })
                .catch(() => {
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
            }else if(num===5){
                axios({
                    method: 'GET',
                    url: AXIOS_URL+'/admin/knowledge/tree',
                })
                .then(response => {
                    // console.log(response);
                    this.knowData = response.data.data
                })
                .catch(() => {
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
            }else if(num===6){
                axios.post(AXIOS_URL+'/admin/paper/pageSearch',{page:1,size:10})
                .then(response => {
                    // 处理成功响应
                    this.paperTotal = response.data.data.total
                    this.paperData = response.data.data.records
                    var pnum = 2
                    while(pnum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/paper/pageSearch',{page:pnum,size:10})
                        .then(response => {
                            this.paperData = this.paperData.concat(response.data.data.records)
                        })
                        pnum+=1
                    }
                })
                .catch(() => {
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
            }else if(num===7){
                axios({
                    method: 'GET',
                    url: AXIOS_URL+'/admin/exam/list',
                    params:{
                        state: -1,
                    }
                })
                .then(response => {
                    this.examData = response.data.data
                })
                .catch(() => {
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
            }
        },
    },
    components:{
        AdminMessage,AdminUser,AdminTopic,AdminPaper,AdminKnowPoint,AdminCourse,AdminClass
    }
}
</script>


<style>
.leftClassBox p{
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: .6;
    transform: translateX(-50%) translateY(-50%);
}
.leftClassBox{
    position: relative;
    /* background: linear-gradient(to bottom, transparent, #f2f4f7 20%, #f2f4f7 80%, transparent); */
    margin: 0 auto;
    padding: 15px 0;
    transition: .5s ease all;
    border-bottom: #e72b47 2px solid;
    border-top: #e72b47 2px solid;
    box-sizing: border-box;
    width: 80%;
    border-radius: 4px;
}
.leftBtnBox{
    margin: 40px auto 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}
.classCenter img:nth-child(1){
    left: -5px;
    height: 22px;
    position: absolute;
    top: 60%;
    transition: .3s ease all;
    transform: rotateY(180deg) translateY(-50%);
}
.classCenter img:nth-child(2){
    right: -5px;
    height: 22px;
    position: absolute;
    top: 40%;
    transition: .3s ease all;
    transform: translateY(-50%) rotateX(180deg);
}
.classCenter{
    position: relative;
    overflow: hidden;
    margin: 5px 0;
    transition: .5s ease all;
    cursor: pointer;
    color: #e72b47;
    font-size: 14px;
    background-color: transparent;
    border: #e72b47 2px solid;
    display: inline-block;
    padding: 7px 10px;
    text-align: center;
    width: 100%;
    border-radius: 4px;
}
.classCenter:hover{
    background-color: #e72b47;
    color: #436393;
}
.top-bar{
    background-color: #e72b47;
    height: 48px;
    z-index: 99;
}
.top-bar div{
    float: right;
    display: flex;
    align-items: center;
    height: 48px;
}
.top-bar p{
    cursor: pointer;
    font-family: '宋体';
    color: #ffffff;
    display: flex;
    align-items: center;
}
.logoutBtn{
    cursor: pointer;
    transition: .3s;
    font-size: 13px;
}
.logoutBtn:hover{
    color: #e72b47;
}
.body-div{
    padding: 0 8vw;
}
.body-left{
    width: 230px;
    min-height: 80vh;
    background-image: 
        linear-gradient(to bottom, rgb(240, 213, 146, .95), rgb(243, 227, 188, .75)), /* 渐变背景 */
        url('../assets/bgRepeat.jpg'); /* 图片背景 */
    background-size: 70%;
    z-index: 99;
}
.body-right{
    margin-left: 15px;
    z-index: 9;
    min-width: 920px;
    height: 100vh;
}
.body-right::-webkit-scrollbar {
    width: 8px;
}
.body-right::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #e4e4e4;
}
.body-right::-webkit-scrollbar-track {
    border-radius: 12px;
    background: #f2f4f700;
}
</style>