<template>
    <div class="cardBox">
        <div class="messageBox">
            <h3>Python代码实践</h3>
            <div style="display: flex;align-items: start;justify-content: space-between;background-color: #f7f7f7;padding-top: 30px; margin-top: 15px;border-radius: 15px;box-shadow: 0 0 10px 0 #c0c0c0;">
                <div style="display: inline-block;width: 65%;">
                    <textarea ref="codeEditor" v-model="pythonCode" rows="10" cols="50" style="width: 100%;height: 100%;margin: 0 15px;"></textarea>
                </div>
                <div style="display: inline-block;width: 30%;">
                    <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
                    <div>{{ result }}</div>
                </div>
            </div>
            <p class="runBtn" @click="runCode" style="color: #ffffff;">运行代码 RUN</p>
        </div>
    </div>
</template>

<script>
import Sk from 'skulpt';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/python/python';
import CodeMirror from 'codemirror';

export default {
  data() {
    return {
      pythonCode: '#输入你的python代码\nprint("hello world!")',
      result: '',
      errorMessage: '',
      codeMirrorInstance: null
    };
  },
  mounted() {
    // Initialize CodeMirror
    this.codeMirrorInstance = CodeMirror.fromTextArea(this.$refs.codeEditor, {
      mode: 'python',
      lineNumbers: false,
      theme: 'default'
    });
    // Update Python code on input
    this.codeMirrorInstance.on('change', (cm) => {
      this.pythonCode = cm.getValue();
    });
    this.runCode()
  },
  methods: {
    runCode() {
        // Reset result and error message
        this.result = '';
        this.errorMessage = '';

        // Create a function to print result
        const output = (text) => {
            this.result += text + '\n';
        };

        // Configure Skulpt properly
        Sk.configure({
            output,
            read: (x) => Sk.builtinFiles.files[x],
            // Fix for ReferenceError: $builtinmodule is not defined
            __future__: Sk.python3,
        });

        try {
            // Run Python code using Skulpt
            Sk.misceval.asyncToPromise(() => Sk.importMainWithBody('<stdin>', false, this.pythonCode)).catch((err) => {
                this.errorMessage = `Error in the line ${err.traceback[0].lineno}: ` + err.tp$str();
            });
        } catch (err) {
            this.errorMessage = 'An error occurred while executing the code.';
        }
    }
  }
};
</script>

<style>
.CodeMirror {
    margin-left: 25px;
}
</style>
<style scoped>
.error {
  color: red;
}
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
    height: 101vh;
}
.messageBox{
    margin: 25px 60px;
}
.messageBox div{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
}
.messageBox p{
    margin: 0 15px;
    color: #141414;
}
.runBtn{
    line-height: 22px;
    display: inline-block;
    transition: .3s;
    background-color: #e72b47;
    color: #ffffff;
    padding: 5px 12px;
    cursor: pointer;
    border-radius: 4px;
}
.runBtn:hover{
    background-color: #ab2237;
}
</style>
