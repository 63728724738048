import { createRouter, createWebHashHistory } from 'vue-router'
import MyLogin from './components/MyLogin'
import HomeAdmin from './components/HomeAdmin'
import HomeStudent from './components/HomeStudent'
import { AXIOS_URL, getCredentials, decrypt } from './constants.js'
import { ElMessage } from 'element-plus'
import axios from 'axios';

const router = createRouter({
    history: createWebHashHistory(),
    routes:[
        {
            path: '/login',
            component: MyLogin
        },
        {
            path: '/student',
            component: HomeStudent
        },
        {
            path: '/admin',
            component: HomeAdmin
        }
    ]
});
// 全局前置守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/student') {
        if (!getCredentials()) {
            next('/login');
        } else if(getCredentials().urSession.role === 'ADMIN'){
            next('/admin');
        } else {
            next();
            const { username, password } = getCredentials().urSession
            
            axios({
                method: 'POST',
                url: AXIOS_URL+'/common/auth/login',
                data: {
                    username: username,
                    password: decrypt(password),
                    rememberMe: true,
                },
            })
            .then((response)=>{
                if(response.data.code !== 1){
                    ElMessage.error({
                        message: 'token错误，请重新登录',
                    })
                }
            })
            .catch(()=>{
                ElMessage.error({
                    message: '登陆失败',
                })
            })
        }
    } else if(to.path === '/admin'){
        if (!getCredentials()) {
            next('/login');
        } else if(getCredentials().urSession.role === 'STUDENT'){
            next('/student');
        } else {
            next();
            const { username, password } = getCredentials().urSession
            
            axios({
                method: 'POST',
                url: AXIOS_URL+'/common/auth/login',
                data: {
                    username: username,
                    password: decrypt(password),
                    rememberMe: true,
                },
            })
            .then((response)=>{
                if(response.data.code !== 1){
                    ElMessage.error({
                        message: 'token错误，请重新登录',
                    })
                }
            })
            .catch(()=>{
                ElMessage.error({
                    message: '登陆失败',
                })
            })
        }
    } else if(to.path === '/login'){
        console.log(getCredentials());
        if (!getCredentials()) {
            next();
        } else if(getCredentials().urSession.role === 'STUDENT'){
            next('/student');
        } else if(getCredentials().urSession.role === 'ADMIN'){
            next('/admin');
        }
    }
});

export default router;