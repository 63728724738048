<template>
    <div style="position: relative;">
        <h3 style="display: flex;align-items: center;" v-if="isS_">{{ paperFinishData_.title }}</h3>
            <div style="display: flex;margin-left: 15px;">
                <p style="margin-right: 15px;font-size: 13px;opacity: .5;" v-if="!isS_">班级：{{ paperFinishData_.classnameList[0]===undefined?'无班级':paperFinishData_.classnameList[0] }}</p>
                <p style="margin-right: 15px;font-size: 13px;opacity: .5;" v-if="!isS_">姓名：{{ paperFinishData_.name }}</p>
                <p style="margin-right: 15px;font-size: 13px;opacity: .5;" v-if="isS_">满分：{{ paperFinishData_.paperScore }}</p>
                <p style="margin-right: 15px;font-size: 13px;color: #e72b47;">得分：{{ paperFinishData_.userScore }}</p>
                <p style="font-size: 13px;opacity: .5;" v-if="isS_">考试时间：{{ paperFinishData_.startAt }}起，{{ paperFinishData_.endAt }}止</p>
            </div>
            <el-scrollbar height="50vh">
                <p v-if="!isS_&&paperFinishData_.contents.length===0" style="position: absolute; top: 50%;left: 50%; transform: translateX(-50%) translateY(-50%);font-size: 14px;opacity: .65;">此卷为白卷</p>
                <div style="margin-top: 10px;">
                    <div v-for="(kind, index1) in paperFinishData_.contents" :key="index1" style="margin-bottom: 10px">
                        <p style="line-height: 35px;font-weight: 600;font-size: 16px;">{{index1 === 0 ? '一，' : index1 === 1 ? '二，' : '三，'}}{{box_type[kind.questionType] }}（共{{ kind.questionNum }}题）</p>
                        <div v-for="(item, index2) in kind.questions" :key="index2" style="margin: 7px 18px;">
                            <p style="margin-bottom: 3px;">{{ index2+1 }}，{{ item.questionTitle }}</p>
                            <div style="position: relative;">
                                <el-radio-group v-model="paperFinishData_.contents[index1].questions[index2].userAnswer" style="width: 80%;margin-left: 25px;margin-top: 5px;margin-bottom: 15px;" v-if="kind.questionType!==1">
                                    <el-radio
                                        v-for="(value, key) in JSON.parse(item.questionOptions)"
                                        :key="key"
                                        :label="key"
                                        :value="value"
                                        style="display: inline-block; width: 100%;line-height: 35px;"
                                        :disabled="true"
                                    >
                                        {{ key }}，{{ value }}
                                    </el-radio>
                                </el-radio-group>
                                <el-checkbox-group style="width: 80%; margin-left: 25px; margin-bottom: 15px; margin-top: 5px;"  v-if="kind.questionType===1">
                                    <el-checkbox
                                        v-for="(value, key) in JSON.parse(item.questionOptions)"
                                        :key="key"
                                        :label="key"
                                        :value="value"
                                        style="display: inline-block; width: 100%; line-height: 35px;"
                                        :checked="item.userAnswer.includes(key)"
                                        :disabled="true"
                                    >
                                        {{ key }}，{{ value }}
                                    </el-checkbox>
                                </el-checkbox-group>
                                <p v-if="item.questionAnswer!==item.userAnswer" style="color: #e72b47;font-size: 13px; margin: 6px 0 10px">{{`回答错误，正确答案为：${item.questionAnswer}`}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
    </div>
</template>

<script>
export default {
    props:['paperFinishData','isS'],
    data(){
        return{
            box_type:['单选题','多选题','判断题'],
            paperFinishData_: this.paperFinishData,
            isS_: this.isS,
        }
    },
    watch: {
        paperFinishData: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.paperFinishData_ = newVal;
            }
        },
        isS: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.isS_ = newVal;
            }
        },
    },

}
</script>
<style>
.el-radio__input.is-disabled.is-checked .el-radio__inner {
    border-color: var(--el-color-primary);
    background: var(--el-color-primary);
}
.el-radio__input.is-disabled.is-checked+span.el-radio__label {
    color: var(--el-color-primary);
    cursor: not-allowed;
}
input[disabled]{opacity:1}
input:disabled{
color:black;
opacity: 1;
-webkit-text-fill-color: red;
border-color: rgb(21, 21, 21);
}
</style>
<style scoped>
h3 {
    margin-bottom: 15px;
    font-size: 20px;
}
</style>