<template>
    <div class="cardBox">
        <div class="messageBox">
            <h3>知识点管理</h3>
            <el-tree
                style="width: 100%"
                :data="knowDataWillShow"
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
                >
                <template #default="{ node, data }">
                    <span class="custom-tree-node">
                    <span>{{ node.label }}</span>
                    <span>
                        <a @click="append(data)" class="treeBtn"> 添加子项 </a>
                        <a style="margin-left: 8px" @click="changeShow(data)" class="treeBtn"> 修改该项 </a>
                        <a style="margin-left: 8px" @click="deleteShow(data)" class="treeBtn"> 删除该项（包括所有子项） </a>
                    </span>
                    </span>
                </template>
            </el-tree>
            <el-dialog v-model="dialogTableVisible" title="添加知识点" width="800" :append-to-body=true @close="cancel">
                <span class="textLabel">知识点名称</span><el-input type="text" v-model="label" placeholder="添加知识点名称"/>
                <div style="display: flex;justify-content: right; margin-top: 15px;">
                <div @click="cancel" class="buttomCancel">取消</div>
                <div @click="add" class="buttomSubmit">添加</div></div>
            </el-dialog>

            <el-dialog v-model="changeVisible" title="编辑知识点" width="800" :append-to-body=true @close="cancel_change">
                <span class="textLabel">知识点名称</span><el-input type="text" v-model="change_label" placeholder="添加知识点名称"/>
                <div style="display: flex;justify-content: right; margin-top: 15px;">
                <div @click="cancel_change" class="buttomCancel">取消</div>
                <div @click="change" class="buttomSubmit">修改</div></div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { AXIOS_URL } from '../constants.js'
import axios from 'axios';

export default {
    props:['knowData'],
    data(){
        return{
            knowDataWillShow:[],
            id:0,
            label:'',
            change_label:'',
            dialogTableVisible:false,
            changeVisible:false,
        }
    },
    mounted(){
        
    },
    watch: {
        knowData: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.knowDataWillShow = newVal;
            }
        },
    },
    methods:{
        deleteShow(data){
            ElMessageBox.confirm(
                '确认删除该知识点（包括所有子节点）？',
                '删除确认',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
            .then(() => {
                axios.delete(`${AXIOS_URL}/admin/knowledge/delete`, {
                    headers: {
                        'Content-Type': 'application/json' // 可选：设置请求的内容类型
                    },
                    data: {
                        id: data.id
                    }
                })
                .then(res => {
                    // 处理响应
                    if(res.data.code===1){
                        ElMessage({
                            message: '知识点删除成功',
                            type: 'success',
                        })
                        this.re()
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '知识点删除失败',
                    })
                });
            })
            .catch(() => {
            })
        },
        cancel_change(){
            this.changeVisible = false
        },
        cancel(){
            this.dialogTableVisible = false
        },
        append(data){
            this.id = data.id
            this.dialogTableVisible = true
        },
        changeShow(data){
            this.id = data.id
            this.changeVisible = true
            this.change_label = data.label
        },
        change(){
            axios.put(AXIOS_URL+'/admin/knowledge/update',{id:this.id,label:this.change_label})
            .then((res)=>{
                if(res.data.code===1){
                    this.changeVisible = false
                    ElMessage({
                        message: '知识点修改成功',
                        type: 'success',
                    })
                    this.change_label = ''
                    this.re()
                }
            })
            .catch(()=>{
                ElMessage.error({
                    message: '知识点修改失败',
                })
            })
        },
        add(){
            axios.post(AXIOS_URL+'/admin/knowledge/add',{parentId:this.id,label:this.label})
            .then((res)=>{
                if(res.data.code===1){
                    this.dialogTableVisible = false
                    ElMessage({
                        message: '知识点新建成功',
                        type: 'success',
                    })
                    this.label = ''
                    this.re()
                }
            })
            .catch(()=>{
                ElMessage.error({
                    message: '知识点新建失败',
                })
            })
        },
        re(){
            axios({
                method: 'GET',
                url: AXIOS_URL+'/admin/knowledge/tree',
            })
            .then(response => {
                this.knowDataWillShow = response.data.data
            })
            .catch(() => {
                ElMessage.error({
                    message: '获取数据失败',
                })
            });
        }
    }
}
</script>

<style scoped>
.treeBtn{
    font-size: 12px;
    padding: 2px 11px;
    transition: .3s ease;
    border: 1px #dcdfe6 solid;
    background-color: transparent;
    color: #606266;
    border-radius: 2px;
}
.treeBtn:hover{
    border-color: #ffc6c6;
    background-color: #ffecec;
    color: #eb2b47;
}
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
    min-height: 101vh;
}
.messageBox{
    margin: 25px 60px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 35px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>