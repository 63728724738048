<template>
    <div>
        <div class="allBox">
            <h3>用户登录</h3>
            <div class="inputBox">
                <el-input
                    v-model="username"
                    type="input"
                    placeholder="在此输入账号"
                    clearable
                    class="myInput"
                    :prefix-icon="userIcon"
                />
                <el-input
                    v-model="password"
                    type="password"
                    placeholder="在此输入密码"
                    show-password
                    clearable
                    class="myInput"
                    :prefix-icon="pwdIcon"
                />
            </div>
            <el-checkbox type="checkbox" class="checkbox" v-model="isChecked">记住我</el-checkbox>
            <el-button type="primary" round color="#e72b47" auto-insert-space class="btn" @click="ClickLoginBtn">登录</el-button>
        </div>
    </div>
</template>


<script>
import { UserFilled, Lock } from '@element-plus/icons-vue'
import { AXIOS_URL, encrypt } from '../constants.js'
import { ElMessage } from 'element-plus'
import axios from 'axios';

axios.defaults.withCredentials = true
export default {
    data(){
        return({
            username: '',
            password: '',
            userIcon: UserFilled,
            pwdIcon: Lock,
            isChecked: false,
        })
    },
    methods:{
        ClickLoginBtn() {
            if(this.isValidString(this.username)&&this.isValidString(this.password)){
                axios.post(AXIOS_URL+'/common/auth/login',{
                    username: this.username,
                    password: this.password,
                    rememberMe: this.isChecked,
                })
                .then((response)=>{
                    if(response.data.code === 1){
                        axios({
                            method: 'GET',
                            url: AXIOS_URL+'/common/account/getInfo',
                        })
                        .then((response)=>{
                            const role = response.data.data.role
                            const name = response.data.data.name
                            const sex = response.data.data.sex
                            this.saveCredentials(this.username, this.password, role, name, sex, (this.isChecked ? 720 : 24))
                            if(role === 2){
                                this.$router.push('/admin');
                            }else if(role === 0){
                                this.$router.push('/student');
                            }
                        })
                        .catch(()=>{
                            ElMessage.error({
                                message: '信息获取失败',
                            });
                            // console.log(err);
                        })
                    }else{
                        ElMessage.error({
                            message: '账号密码错误',
                        });
                    }
                })
                .catch(()=>{
                    ElMessage.error({
                        message: '登陆失败',
                    });
                    // console.log(err);
                })
            }else{
                ElMessage({
                    message: '请输入合法的账号密码',
                    type: 'warning'
                });
            }
        },
        // 正则表达式用于匹配：一个或多个数字、字母或特殊字符
        isValidString(str) {
            const regex = /^[a-zA-Z0-9\s~`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
            return regex.test(str) && str.trim().length > 0;
        },
        // 保存账号密码进session
        saveCredentials(username, password, role, name, sex, hours) {
            const expiryTime = new Date().getTime() + (hours * 60 * 60 * 1000); // 24 小时后的时间戳
            const credentials = {
                username: username,
                password: encrypt(password),
                role: role,
                name: name,
                sex: sex,
                expiryTime: expiryTime
            };
            sessionStorage.setItem('credentials', JSON.stringify(credentials));
        },
    }
}
</script>


<style scoped>
.myInput{
    width: 280px;
    margin: 13px 15px;
    height: 45px;
    --el-input-focus-border-color: #e72b47;
    --el-input-border-radius: 25px;
}
.inputBox{
    display: flex;
    flex-direction: column;
}
.btn{
    border:#f0d592 2px solid;
    margin: 18px 15px;
    height: 45px;
    width: 280px;
}
.allBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-55%);
    background-color: #ffffff;
    padding: 60px 70px;
    border-radius: 12px;
    width: 310px;
    box-shadow: #eeeff1 0 0 70px 25px;
}
h3{
    font-weight: 400;
    margin: 0 0 30px 12px;
}
.checkbox{
    --el-checkbox-checked-input-border-color: #e72b47;
    --el-checkbox-checked-bg-color: #e72b47;
    --el-checkbox-input-border-color-hover: #e72b47;
    --el-checkbox-checked-text-color: #606266;
    margin: 0 18px 0;
    float: right;
}
</style>