import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import ArcoVue from '@arco-design/web-vue';
import 'element-plus/dist/index.css'
import '@arco-design/web-vue/dist/arco.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import router from './router.js'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.use(ArcoVue);
app.use(VueVideoPlayer);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')
