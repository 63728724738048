<template>
    <div class="cardBox">
        <div class="messageBox">
            <h3>试卷管理</h3>
            <div @click="add()" class="buttomTop">新增试卷</div>
            <el-table :data="slicedData" style="width: 100%">
                <el-table-column label="Id" prop="id" :show-overflow-tooltip=true width="70px"/>
                <el-table-column label="试卷名" prop="title" :show-overflow-tooltip="true" width="160px">
                    <template #default="scope">
                        <div style="white-space: normal;">{{ scope.row.title }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="分值" prop="score" :show-overflow-tooltip=true width="80px"/>
                <el-table-column label="难度" :show-overflow-tooltip=true width="80px">
                    <template #default="scope">
                        {{ scope.row.difficulty===0?'简单':scope.row.difficulty===1?'一般':scope.row.difficulty===2?'中等':scope.row.difficulty===3?'困难':'特困' }}
                    </template>
                </el-table-column>
                <el-table-column label="开始时间" prop="startAt" :show-overflow-tooltip="true" width="120px">
                    <template #default="scope">
                        <div style="white-space: normal;">{{ scope.row.startAt }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="结束时间" prop="endAt" :show-overflow-tooltip="true" width="120px">
                    <template #default="scope">
                        <div style="white-space: normal;">{{ scope.row.endAt }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" :show-overflow-tooltip="true" width="70px">
                    <template #default="scope">
                        <el-tag type="danger">{{ scope.row.state===0?'拟草稿':scope.row.state===1?'待开始':scope.row.state===2?'进行中':scope.row.state===3?'待批阅':'已完成' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="right" width="130px">
                <template #header>
                    <div>操作</div>
                </template>
                <template #default="scope">
                    <el-button size="small" @click="moreMessage(scope)">题目详情</el-button>
                    <el-button size="small" @click="deletePaper(scope)" :disabled="scope.row.state>=2">删除试卷</el-button>
                    <el-button size="small" @click="rePaperMessage(scope)" :disabled="scope.row.state>=2">编辑试卷</el-button>
                    <el-button size="small" @click="pushPaper(scope)" :disabled="scope.row.state>=2">发布试卷</el-button>
                    <el-button size="small" @click="showStudentPaper(scope)" :disabled="scope.row.state<2">查看交卷</el-button>
                </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="paperTotal_a" :default-page-size="10" v-model:current-page="currentPage"/>
            <el-dialog v-model="moreMessageVisible" title="考试题目详情" width="800" :append-to-body=true :destroy-on-close=true @close="moreMessage_cancel">
                <span class="textLabel">试卷名称</span><p class="paperName"> {{ paperTitle_moreMessage }}</p>
                <el-scrollbar max-height="400px">
                <span class="textLabel" v-if="paperMoreMessage_dx!==undefined">题目列表（<b>单选题</b> | 题量：{{paperMoreMessage_dx.questionNum}}道 | 总分：{{paperMoreMessage_dx.localScore}}分）</span>
                <el-scrollbar max-height="300px" v-if="paperMoreMessage_dx!==undefined">
                    <el-collapse style="width: 96%;margin: 5px auto;">
                        <el-collapse-item v-for="(item,index) in paperMoreMessage_dx.questions" :name="index" :key="item.questionId">
                            <template #title>
                                <el-text truncated style="max-width: 300px;">{{ index+1+'，'+item.title }}</el-text>
                                <el-tag type="danger" style="padding: 0 6px;margin-left: 5px">{{ item.questionScore }}分</el-tag>
                                <el-tag type="danger" v-for="tag in item.knowledge" style="padding: 0 6px;margin-left: 5px" :key="tag">{{ getLabelByIdRecursive(tag,tagNameBox) }}</el-tag>
                            </template>
                            <div>
                                <el-descriptions size="small">
                                    <el-descriptions-item label="题干及选项：">{{ item.title }}</el-descriptions-item>
                                </el-descriptions>
                                <el-radio-group v-model="item.options" style="width: 80%;margin-left: 25px; margin-bottom: 15px; margin-top: 5px;position: relative;">
                                    <div style="height: 100%;"></div>
                                    <el-radio
                                        v-for="(value, key) in JSON.parse(item.options)"
                                        :key="key"
                                        :label="key"
                                        :value="value"
                                        style="display: inline-block; width: 100%;line-height: 25px;font-size: 12px;"
                                        :disabled="true"
                                    >
                                        {{ key }}，{{ value }}
                                    </el-radio>
                                </el-radio-group>
                                <el-descriptions size="small">
                                    <el-descriptions-item label="正确答案："><p style="color:#e62b47;display: inline-block;">{{ item.answer }}</p></el-descriptions-item>
                                </el-descriptions>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-scrollbar>

                <span class="textLabel" v-if="paperMoreMessage_fx!==undefined">题目列表（<b>多选题</b> | 题量：{{paperMoreMessage_fx.questionNum}}道 | 总分：{{paperMoreMessage_fx.localScore}}分）</span>
                <el-scrollbar max-height="300px" v-if="paperMoreMessage_fx!==undefined">
                    <el-collapse style="width: 96%;margin: 5px auto;">
                        <el-collapse-item v-for="(item,index) in paperMoreMessage_fx.questions" :name="index" :key="item.questionId">
                            <template #title>
                                <el-text truncated style="max-width: 300px;">{{ index+1+'，'+item.title }}</el-text>
                                <el-tag type="danger" style="padding: 0 6px;margin-left: 5px">{{ item.questionScore }}分</el-tag>
                                <el-tag type="danger" v-for="tag in item.knowledge" style="padding: 0 6px;margin-left: 5px" :key="tag">{{ getLabelByIdRecursive(tag,tagNameBox) }}</el-tag>
                            </template>
                            <div>
                                <el-descriptions size="small">
                                    <el-descriptions-item label="题干及选项：">{{ item.title }}</el-descriptions-item>
                                </el-descriptions>
                                <el-radio-group v-model="item.options" style="width: 80%;margin-left: 35px;">
                                    <el-radio
                                        v-for="(value, key) in JSON.parse(item.options)"
                                        :key="key"
                                        :label="key"
                                        :value="value"
                                        style="display: inline-block; width: 100%;line-height: 25px;font-size: 12px;"
                                        :disabled="true"
                                    >
                                        {{ key }}，{{ value }}
                                    </el-radio>
                                </el-radio-group>
                                <el-descriptions size="small">
                                    <el-descriptions-item label="正确答案："><p style="color:#e62b47;display: inline-block;">{{ item.answer }}</p></el-descriptions-item>
                                </el-descriptions>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-scrollbar>

                <span class="textLabel" v-if="paperMoreMessage_pd!==undefined">题目列表（<b>判断题</b> | 题量：{{paperMoreMessage_pd.questionNum}}道 | 总分：{{paperMoreMessage_pd.localScore}}分）</span>
                <el-scrollbar max-height="300px" v-if="paperMoreMessage_pd!==undefined">
                    <el-collapse style="width: 96%;margin: 5px auto;">
                        <el-collapse-item v-for="(item,index) in paperMoreMessage_pd.questions" :name="index" :key="item.questionId">
                            <template #title>
                                <el-text truncated style="max-width: 300px;">{{ index+1+'，'+item.title }}</el-text>
                                <el-tag type="danger" style="padding: 0 6px;margin-left: 5px">{{ item.questionScore }}分</el-tag>
                                <el-tag type="danger" v-for="tag in item.knowledge" style="padding: 0 6px;margin-left: 5px" :key="tag">{{ getLabelByIdRecursive(tag,tagNameBox) }}</el-tag>
                            </template>
                            <div>
                                <el-descriptions size="small">
                                    <el-descriptions-item label="题干及选项：">{{ item.title }}</el-descriptions-item>
                                </el-descriptions>
                                <el-radio-group v-model="item.options" style="width: 80%;margin-left: 35px;">
                                    <el-radio
                                        v-for="(value, key) in JSON.parse(item.options)"
                                        :key="key"
                                        :label="key"
                                        :value="value"
                                        style="display: inline-block; width: 100%;line-height: 25px;font-size: 12px;"
                                        :disabled="true"
                                    >
                                        {{ key }}，{{ value }}
                                    </el-radio>
                                </el-radio-group>
                                <el-descriptions size="small">
                                    <el-descriptions-item label="正确答案："><p style="color:#e62b47;display: inline-block;">{{ item.answer }}</p></el-descriptions-item>
                                </el-descriptions>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-scrollbar>
                </el-scrollbar>
            </el-dialog>

            <el-dialog v-model="addPaperVisible" :title="isChangePaper?'修改试卷':'手动组卷'" width="800" :append-to-body=true :destroy-on-close=true @close="add_cancel">
                <span class="textLabel">试卷名称</span><el-input type="text" v-model="add_paperName" placeholder="添加试卷名称"/>
                <el-config-provider :locale="locale">
                    <span class="textLabel">考试时间（开始）</span>
                    <el-date-picker
                        v-model="paperStartTime"
                        type="datetime"
                        placeholder="选择考试开始时间"
                    />
                    <span class="textLabel">考试时间（结束）</span>
                    <el-date-picker
                        v-model="paperEndTime"
                        type="datetime"
                        placeholder="选择考试结束时间"
                    />
                </el-config-provider>
                <span class="textLabel">试卷题目筛选条件设定</span>
                <div class="searchBox">
                    <p>具体条件搜索</p>
                    <div>
                        <el-tree-select placeholder="选填知识点" v-model="search_knowledge" multiple :data="tagNameBox" check-strictly=true :render-after-expand="false" :value-key="'id'" size="small" style="width:450px;" clearable></el-tree-select>
                        <div style="display: flex;justify-content: space-between; margin-top: 3px;">
                            <el-input
                                v-model="search_score"
                                style="width: 80px"
                                placeholder="选填分数"
                                clearable
                                size="small"
                            />
                            <el-select v-model="search_difficulty" placeholder="选填难度" style="width: 100px" size="small" clearable>
                                <el-option
                                    v-for="item in box_difficulty"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id"
                                    clearable
                                />
                            </el-select>
                            <el-select v-model="search_questionType" placeholder="选填题型" style="width: 100px" size="small" clearable>
                                <el-option
                                    v-for="item in box_type"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id"
                                    clearable
                                />
                            </el-select>
                            <el-select v-model="search_changed" placeholder="选填状态" style="width: 100px" size="small" clearable>
                                <el-option
                                    v-for="item in box_changed"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id"
                                    clearable
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="searchBoxdiv" @click="search">设置</div>
                </div>
                <span class="textLabel">试卷题目选择</span>
                <el-select v-model="topicCheck" placeholder="选择题目" multiple :collapse-on-blur="false">
                    <el-option
                        v-for="item in topicList_willshow"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                    />
                    <template #footer>
                        <!-- <span class="textLabel">知识点</span> -->
                        <el-input
                            v-model="topicName"
                            class="option-input"
                            placeholder="输入题干关键词快捷筛选"
                            size="small"
                        />
                    </template>
                </el-select>
                <!-- <span class="textLabel">题目筛选条件</span><el-tree-select placeholder="筛选知识点" v-model="knowledgeCheck" multiple :data="knowledgeList" check-strictly=true :render-after-expand="false" :value-key="'id'" size="small" style="width: 300px;" @change="handleInternalChange">
                </el-tree-select> -->
                <div style="display: flex;justify-content: right; margin-top: 15px;">
                <div @click="add_cancel" class="buttomCancel">取消</div>
                <div @click="add_change" class="buttomSubmit">{{isChangePaper?'修改':'新增'}}</div></div>
            </el-dialog>

            <el-dialog v-model="pushPaperVisible" title="发布试卷" width="800" :append-to-body=true :destroy-on-close=true @close="pushPaper_cancel">
                <div style="display: flex;align-items: center;flex-direction: column;">
                <el-transfer
                    filterable
                    v-model="studentList_cli"
                    :titles="['学生池', '发布对象']"
                    filter-placeholder="输入学号/名称快速筛选"
                    :data="studentList_after"
                >
                    <template #left-footer>
                        <div style="display: flex;justify-content: space-around;margin-top: 8px;">
                            <el-select v-model="studentList_class_choose" placeholder="班级筛选 / 全选" style="width: 180px" size="small" clearable>
                                <el-option
                                    v-for="item in studentList_class"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id"
                                />
                            </el-select>
                            <el-button class="transfer-footer" size="small" @click="searchStudentByClassNum">筛选</el-button>
                        </div>
                    </template>
                </el-transfer>
                </div>
                <div style="display: flex;justify-content: right; margin-top: 15px;">
                <div @click="pushPaper_cancel" class="buttomCancel">取消</div>
                <div @click="pushPaper_change" class="buttomSubmit">发布</div></div>
            </el-dialog>

            <el-dialog v-model="studentPaperVisible" title="上交试卷列表" width="800" :append-to-body=true :destroy-on-close=true>
                <el-table :data="studentPaper" style="width: 100%">
                    <span class="textLabel">班级</span>
                    <el-table-column label="班级" :show-overflow-tooltip="true" width="180">
                        <template #default="scope">
                            {{ scope.row.classnameList[0]===undefined?'无班级':scope.row.classnameList[0] }}
                        </template>
                    </el-table-column>
                    <span class="textLabel">试卷编号</span><el-table-column label="试卷编号" prop="id" width="80"/>
                    <span class="textLabel">学号</span><el-table-column label="学号" prop="username" width="140"/>
                    <span class="textLabel">姓名</span><el-table-column label="姓名" prop="name" width="120"/>
                    <span class="textLabel">性别</span><el-table-column label="性别" prop="sex" width="70">
                        <template #default="scope">
                            {{ scope.row.sex === 1?'男':'女' }}
                        </template>
                    </el-table-column>
                    <span class="textLabel">得分</span><el-table-column label="得分" prop="userScore" width="70"/>
                    <el-table-column align="right">
                        <template #default="scope">
                            <el-button size="small" @click="seemorePaper(scope)"
                            >查看答卷情况</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next" :total="studentPaperTotal" :default-page-size="10" v-model:current-page="studentPaperCurrent"/>
            </el-dialog>

            <el-dialog
                v-model="isshowpaperFinish"
                title="试卷详情"
                width="1000"
                append-to-body
            >
                <PaperAfter :paperFinishData="paperFinishData" :isS="false"/>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { AXIOS_URL } from '../constants.js'
import axios from 'axios'
import zhCn from "element-plus/es/locale/lang/zh-cn"
import { ref } from "vue"
import PaperAfter from './PaperAfter.vue'

export default {
    setup() {
        const value1 = ref();
        return {
            value1,
            locale: zhCn,
        };
    },
    props:['paperData','paperTotal'],
    data(){
        return{
            search_changed:null,
            search_questionType:null,
            search_difficulty:null,
            search_knowledge:null,
            search_score:null,
            box_type:[{id:0,label:'单选题'},{id:1,label:'多选题'},{id:2,label:'判断题'}],
            box_knowledge:[],
            box_difficulty:[{id:0,label:'简单'},{id:1,label:'一般'},{id:2,label:'中等'},{id:3,label:'困难'},{id:4,label:'特困'}],
            box_changed:[{id:1,label:'可修改'},{id:0,label:'不可修改'}],

            currentPage:1,
            paperStartTime:'',
            paperEndTime:'',
            paperTotal_a:this.paperTotal,
            paperDataWillShow:[],
            paperMoreMessage_dx:[],
            paperMoreMessage_fx:[],
            paperMoreMessage_pd:[],
            tagNameBox:[],
            moreMessageVisible:false,

            paperTitle_moreMessage:'',
            activeNames:0,

            topicList:[],
            addPaperVisible:false,
            add_paperName:'',
            topicCheck:[],
            topicName:'',
            knowledgeList:[],
            knowledgeCheck:[],
            isPointerNone:'all',

            pushPaperVisible:false,
            pushPaper_id:-1,
            studentList_org:[],
            studentList_after:[],
            studentList_cli:[],
            studentList_class:[],
            studentList_class_choose:[],

            changePaper_id:-1,
            change_or_add:0,

            studentPaper:[],
            studentPaperVisible:false,
            studentPaperTotal:0,
            studentPaperCurrent:1,

            paperFinishData:{},
            isshowpaperFinish:false,

            isChangePaper:true,
        }
    },
    computed:{
        topicList_willshow() {
            return this.topicList.filter(item => item.title.includes(this.topicName))
        },
        slicedData() {
            const start = (this.currentPage - 1) * 10;
            const end = Math.min(this.currentPage * 10, this.paperDataWillShow.length);
            return this.paperDataWillShow.slice(start, end);
        },
    },
    methods:{
        searchStudentByClassNum(){
            this.getPageMessage(this.studentList_class_choose===''?null:this.studentList_class_choose)
        },
        seemorePaper(scope){
            axios({
                method: 'GET',
                url: AXIOS_URL+'/admin/paper/detailStudentPaper',
                params:{
                    id: scope.row.id
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if(res.data.code===1){
                    this.paperFinishData = res.data.data
                    this.isshowpaperFinish = true
                    ElMessage({
                        message: '考试结果获取成功',
                        type: 'success',
                    })
                }else{
                    ElMessage.error({
                        message: '考试结果获取失败',
                    })
                }
            })
            .catch(()=>{
                // console.log(err);
                ElMessage.error({
                    message: '考试结果获取失败',
                })
            })
        },
        searchStudentPaper(paperId,username,name,sex,state,classId){
            axios.post(AXIOS_URL+'/admin/paper/pageSearchStudentPaper',{id:paperId,page:1,size:10,username,name,sex,state,classId})
            .then(response => {
                this.studentPaperTotal = response.data.data.total
                this.studentPaper = response.data.data.records
                var unum = 2
                while(unum<=response.data.data.pages){
                    axios.post(AXIOS_URL+'/admin/paper/pageSearchStudentPaper',{id:paperId,page:unum,size:10,username,name,sex,state,classId})
                    .then(response => {
                        this.studentPaper = response.data.data.records.concat(this.studentPaper)
                    })
                    this.unum+=1
                }
                this.studentPaperVisible = true
                ElMessage({
                    message: '考生提交试卷获取成功',
                    type: 'success',
                })
                // console.log(response.data.data.records);
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '考生提交试卷列表失败',
                })
            });
        },
        showStudentPaper(scope){
            this.searchStudentPaper(scope.row.id,null,null,null,null,null)
        },
        rePaperMessage(scope){
            this.getTagName()
            this.isChangePaper = true
            this.change_or_add = 0
            this.changePaper_id = scope.row.id
            axios({
                method: 'GET',
                url: AXIOS_URL+'/admin/paper/get',
                params:{
                    id: scope.row.id
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if(res.data.code===1){
                    this.getTopicList(null,null,null,null,null,null)
                    this.getKnowledgeList()
                    this.addPaperVisible = true
                    this.topicName = ''
                    this.topicCheck = res.data.data.questionIds
                    this.search_changed = null
                    this.search_questionType = null
                    this.search_difficulty = null
                    this.search_score = null
                    this.search_knowledge = null
                    this.paperEndTime = res.data.data.endAt
                    this.paperStartTime = res.data.data.startAt
                    this.add_paperName = res.data.data.title
                }else{
                    ElMessage.error({
                    message: '试卷数据获取失败',
                })
                }
            })
            .catch(()=>{
                ElMessage.error({
                    message: '试卷数据获取失败',
                })
            })
        },
        search(){
            this.getTopicList(null,this.search_score===''?null:this.search_score,this.search_difficulty===''?null:this.search_difficulty,this.search_questionType===''?null:this.search_questionType,this.search_knowledge.length===0?null:this.search_knowledge,this.search_changed===''?null:this.search_changed)
        },
        pushPaper_cancel(){
            this.pushPaperVisible = false
        },
        pushPaper(scope){
            this.pushPaperVisible = true
            this.pushPaper_id = scope.row.id
            this.getPageMessage(null)
        },
        pushPaper_change(){
            axios.post(AXIOS_URL+'/admin/paper/publish',{id:this.pushPaper_id,ids:this.studentList_cli})
            .then((res)=>{
                if(res.data.code===1){
                    this.pushPaperVisible = false
                    ElMessage({
                        message: '试卷发布成功',
                        type: 'success',
                    })
                    this.studentList_cli = []
                }else{
                    ElMessage.error({
                        message: res.data.msg,
                    })
                }
            })
            .catch(()=>{
                // console.log(err);
                ElMessage.error({
                    message: '试卷发布失败',
                })
            })
        },
        re(){
            axios.post(AXIOS_URL+'/admin/paper/pageSearch',{page:1,size:10})
            .then(response => {
                // 处理成功响应
                this.paperTotal_a = response.data.data.total
                this.paperDataWillShow = response.data.data.records
                // this.paperDataWillShow.sort((a, b) => {
                //     return a.id - b.id; // 按 id 升序排序
                // });
                var pnum = 2
                while(pnum<=response.data.data.pages){
                    axios.post(AXIOS_URL+'/admin/paper/pageSearch',{page:pnum,size:10})
                    .then(response => {
                        this.paperDataWillShow = this.paperDataWillShow.concat(response.data.data.records)
                        // this.paperDataWillShow.sort((a, b) => {
                        //     return a.id - b.id; // 按 id 升序排序
                        // });
                    })
                    pnum+=1
                }
            })
            .catch(() => {
                ElMessage.error({
                    message: '获取数据失败',
                })
            });
        },
        add_change(){
            if(this.change_or_add===0){
                // console.log(this.changePaper_id);
                // console.log(this.topicCheck);
                // console.log(this.add_paperName);
                // console.log(typeof(this.paperEndTime)==='string'?this.paperEndTime:`${this.paperEndTime.getFullYear()}-${(this.paperEndTime.getMonth() + 1).toString().padStart(2, '0')}-${this.paperEndTime.getDate().toString().padStart(2, '0')} ${this.paperEndTime.getHours().toString().padStart(2, '0')}:${this.paperEndTime.getMinutes().toString().padStart(2, '0')}:${this.paperEndTime.getSeconds().toString().padStart(2, '0')}`);
                // console.log(typeof(this.paperStartTime)==='string'?this.paperStartTime:`${this.paperStartTime.getFullYear()}-${(this.paperStartTime.getMonth() + 1).toString().padStart(2, '0')}-${this.paperStartTime.getDate().toString().padStart(2, '0')} ${this.paperStartTime.getHours().toString().padStart(2, '0')}:${this.paperStartTime.getMinutes().toString().padStart(2, '0')}:${this.paperStartTime.getSeconds().toString().padStart(2, '0')}`);
                axios.put(AXIOS_URL+'/admin/paper/update',{
                    id:this.changePaper_id,
                    questionIds: this.topicCheck,
                    title: this.add_paperName,
                    endAt:typeof(this.paperEndTime)==='string'?this.paperEndTime:`${this.paperEndTime.getFullYear()}-${(this.paperEndTime.getMonth() + 1).toString().padStart(2, '0')}-${this.paperEndTime.getDate().toString().padStart(2, '0')} ${this.paperEndTime.getHours().toString().padStart(2, '0')}:${this.paperEndTime.getMinutes().toString().padStart(2, '0')}:${this.paperEndTime.getSeconds().toString().padStart(2, '0')}`,
                    startAt:typeof(this.paperStartTime)==='string'?this.paperStartTime:`${this.paperStartTime.getFullYear()}-${(this.paperStartTime.getMonth() + 1).toString().padStart(2, '0')}-${this.paperStartTime.getDate().toString().padStart(2, '0')} ${this.paperStartTime.getHours().toString().padStart(2, '0')}:${this.paperStartTime.getMinutes().toString().padStart(2, '0')}:${this.paperStartTime.getSeconds().toString().padStart(2, '0')}`,
                }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response);
                    if(response.data.code===1){
                        ElMessage({
                            message: '试卷更改成功',
                            type: 'success',
                        })
                        this.topicCheck = []
                        this.add_paperName = ''
                        this.paperStartTime = ''
                        this.paperEndTime = ''
                        this.addPaperVisible = false
                        this.re()
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '试卷更改失败',
                    })
                });
            }else{
                axios.post(AXIOS_URL+'/admin/paper/add',{
                    questionIds: this.topicCheck,
                    title: this.add_paperName,
                    endAt:`${this.paperEndTime.getFullYear()}-${(this.paperEndTime.getMonth() + 1).toString().padStart(2, '0')}-${this.paperEndTime.getDate().toString().padStart(2, '0')} ${this.paperEndTime.getHours().toString().padStart(2, '0')}:${this.paperEndTime.getMinutes().toString().padStart(2, '0')}:${this.paperEndTime.getSeconds().toString().padStart(2, '0')}`,
                    startAt:`${this.paperStartTime.getFullYear()}-${(this.paperStartTime.getMonth() + 1).toString().padStart(2, '0')}-${this.paperStartTime.getDate().toString().padStart(2, '0')} ${this.paperStartTime.getHours().toString().padStart(2, '0')}:${this.paperStartTime.getMinutes().toString().padStart(2, '0')}:${this.paperStartTime.getSeconds().toString().padStart(2, '0')}`,
                })
                .then(response => {
                    if(response.data.code===1){
                        ElMessage({
                            message: '新建试卷成功',
                            type: 'success',
                        })
                        this.topicCheck = []
                        this.add_paperName = ''
                        this.paperStartTime = ''
                        this.paperEndTime = ''
                        this.addPaperVisible = false
                        this.re()
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '新建试卷失败',
                    })
                });
            }
        },
        add_cancel(){
            this.addPaperVisible = false
            this.topicName = ''
            this.topicCheck = ''
            this.search_changed = null
            this.search_questionType = null
            this.search_difficulty = null
            this.search_score = null
            this.search_knowledge = null
            this.paperEndTime = ''
            this.paperStartTime = ''
            this.add_paperName = ''
        },
        add(){
            this.isChangePaper = false
            this.change_or_add = 1
            this.getTagName()
            this.getTopicList(null,null,null,null,null,null,)
            this.getKnowledgeList()
            this.addPaperVisible = true
        },
        getTopicList(title,score,difficulty,questionType,knowledge,changed){
            // console.log(title,score,difficulty,questionType,knowledge,changed);
            axios.post(AXIOS_URL+'/admin/question/pageSearch',{page:1,size:10,title,score,difficulty,questionType,knowledge,changed})
            .then(response => {
                this.topicList = response.data.data.records
                var unum = 2
                while(unum<=response.data.data.pages){
                    axios.post(AXIOS_URL+'/admin/question/pageSearch',{page:unum,size:10,title,score,difficulty,questionType,knowledge,changed})
                    .then(response => {
                        this.topicList = response.data.data.records.concat(this.topicList)
                    })
                    unum+=1
                }
                ElMessage({
                    message: '题目列表获取成功',
                    type: 'success',
                })
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '获取题目列表失败',
                })
            });
        },
        getKnowledgeList(){
            axios.get(AXIOS_URL+'/admin/selectBox/knowledgeTree')
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    this.knowledgeList = response.data.data
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '知识点下拉框获取失败',
                })
            });
        },
        handleChange(){
            // console.log(this.activeNames);
        },
        getLabelByIdRecursive(id, data) {
        for (const item of data) {
            if (item.id === parseInt(id)) {
                return item.label;
            }
            if (item.children && item.children.length > 0) {
                const label = this.getLabelByIdRecursive(id, item.children);
                if (label) {
                    return label;
                }
            }
        }
        return '';
        },
        getTagName(){
            axios.get(AXIOS_URL+'/admin/selectBox/knowledgeTree')
            .then(response => {
                this.tagNameBox = response.data.data
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '知识点信息拉取失败',
                })
            });
        },
        deletePaper(scope){
            ElMessageBox.confirm(
                '确认删除该试卷？',
                '删除确认',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
            .then(() => {
                axios.delete(`${AXIOS_URL}/admin/paper/delete`, {
                    headers: {
                        'Content-Type': 'application/json' // 可选：设置请求的内容类型
                    },
                    data: {
                        id: scope.row.id
                    }
                })
                .then(response => {
                    if(response.data.code===1){
                        ElMessage({
                            message: '试卷删除成功',
                            type: 'success',
                        })
                        this.re()
                    }
                })
                .catch(() => {
                    ElMessage.error({
                        message: '试卷删除失败',
                    })
                });
            })
            .catch(() => {
            })
            
        },
        moreMessage(scope){
            this.getTagName()
            this.moreMessageVisible = true
            this.paperTitle_moreMessage = scope.row.title
            axios({
                method: 'GET',
                url: AXIOS_URL+'/admin/paper/detail',
                params:{
                    id: scope.row.id
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.paperMoreMessage_dx = response.data.data.contents[0]
                this.paperMoreMessage_fx = response.data.data.contents[1]
                this.paperMoreMessage_pd = response.data.data.contents[2]
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '试卷详情信息获取失败',
                })
            });
        },
        getPageMessage(classId){
            ElMessage({
                message: '学生信息获取中...',
                type: 'info',
            })
            axios.post(AXIOS_URL+'/admin/paper/pageSearchPaperUser',{page:1,size:10,courseId:null,classId,username:null,name:null})
            .then(response => {
                // 处理成功响应
                this.studentList_org = response.data.data.records
                var unum = 2
                let promises = [];

                while (unum <= response.data.data.pages) {
                    let promise = axios.post(AXIOS_URL + '/admin/paper/pageSearchPaperUser', { page: unum, size: 10,courseId:null,classId,username:null,name:null })
                        .then(res => {
                            this.studentList_org = res.data.data.records.concat(this.studentList_org);
                            // this.studentList_org.sort((a, b) => {
                            //     return a.id - b.id; // 按 id 升序排序
                            // });
                        })
                        .catch(() => {
                        // 处理错误
                            ElMessage.error({
                                message: '学生列表获取失败',
                            })
                        }); 
                    promises.push(promise);
                    unum += 1;
                }

                Promise.all(promises).then(() => {
                    this.studentList_org = this.studentList_org.map(item => ({
                        key: item.id,
                        label: `${item.username}-${item.name}`
                    }));
                    this.studentList_after = this.studentList_org; // 执行完while再执行下面的代码
                    axios.get(AXIOS_URL+'/admin/selectBox/listClass')
                    .then(response => {
                        // 处理成功响应
                        if(response.data.code===1){
                            this.studentList_class = response.data.data
                            ElMessage({
                                message: '学生列表获取完成',
                                type: 'success',
                            })
                        }
                    })
                    .catch(() => {
                        // 处理错误
                        ElMessage.error({
                            message: '班级下拉框获取失败',
                        })
                    }); 
                });
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '学生获取数据失败',
                })
            });
        },
    },
    watch: {
        paperData: {
            immediate: true, // 立即执行
            handler(newVal) {
                // newVal.sort((a, b) => {
                //     return a.id - b.id; // 按 id 升序排序
                // });
                this.paperDataWillShow = newVal;
            }
        },
    },
    components:{
        PaperAfter
    }
}
</script>

<style>
.el-transfer-panel{
    width: 265px !important;
    box-shadow: 0 5px 12px 2px #797a7b1b;
}
.el-button--primary {
    --el-button-disabled-text-color: #e72b47;
}
.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
    opacity: .6;
}
</style>
<style scoped>
.textLabel{
    margin-top: 15px;
}
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
    min-height: 101vh;
}
.messageBox{
    margin: 25px 60px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 35px;
}
.paperName{
    margin: 3px 0 5px 6px;
    font-size: 16px;
}
.el-transfer-panel{
    width: 265px !important;
}

.searchBox{
    margin: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 90%;
    margin-bottom: 5px;
    box-sizing: border-box;
    overflow: hidden;
    border: #e72b47 1px solid;
    border-radius: 30px;
    background-color: #ffecec;
}
.searchBox p{
    font-size: 13px;
    color: #e72b47;
    font-weight: 600;
    padding-left: 20px;
}
.searchBoxdiv{
    background-color: #e72b47;
    height: 60px;
    width: 55px;
    text-align: center;
    line-height: 60px;
    font-size: 13px;
    color: #ffecec;
    cursor: pointer;
}
</style>