<template>
    <div class="cardBox">
        <div class="messageBox">
            <h3>班级管理</h3>
            <div @click="addVisible=true" class="buttomTop">新增班级</div>
            <div class="buttomTop" style="right: 100px;"><a href="https://clsp.oss-cn-hangzhou.aliyuncs.com/%E7%8F%AD%E7%BA%A7%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx" style="text-decoration: none;" class="hoverStep">下载导入模板</a></div>
            <el-table :data="slicedData" style="width: 100%">
                <el-table-column label="Id" prop="id" show-overflow-tooltip=true width="70px"/>
                <el-table-column label="班级名" prop="name"  show-overflow-tooltip=true width="230px"/>
                <el-table-column label="人数" prop="studentNumber" show-overflow-tooltip=true width="70px"/>
                <el-table-column width="200px" label="添加学生">
                <template #default="scope">
                    <el-button size="small" @click="addWrite(scope)"
                    >手动</el-button
                    >
                    <el-button size="small" @click="addExcel(scope)"
                    >Excel</el-button
                    >
                </template>
                </el-table-column>
                <el-table-column align="right" width="250px">
                <template #header>
                    <el-input v-model="searchName" size="small" placeholder="搜索班级名称" @input="searchByName"/>
                </template>
                <template #default="scope">
                    <el-button size="small" @click="reMessage(scope)"
                    >修改班级信息</el-button
                    >
                    <el-button size="small" @click="showStudent(scope)"
                    >查看班级学生</el-button
                    >
                </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="totalWillShow" :default-page-size="10" v-model:current-page="currentPage"/>
        </div>

        <el-dialog v-model="dialogTableVisible" title="修改班级信息" width="800" :append-to-body=true @close="cancel">
            <span class="textLabel">班级名称</span><el-input type="text" v-model="name" placeholder="班级名称"/>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="cancel" class="buttomCancel">取消</div>
            <div @click="change" class="buttomSubmit">提交</div></div>
        </el-dialog>

        <el-dialog v-model="addVisible" title="创建新班级" width="800" :append-to-body=true :destroy-on-close=true @close="add_cancel">
            <span class="textLabel">班级名称</span><el-input type="text" v-model="add_name" placeholder="班级名称"/>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="add_cancel" class="buttomCancel">取消</div>
            <div @click="add_hange" class="buttomSubmit">新增</div></div>
        </el-dialog>

        <el-dialog v-model="studentVisible" title="班级学生列表" width="800" :append-to-body=true :destroy-on-close=true>
            <el-table :data="studentWillShow" style="width: 100%">
                <span class="textLabel">班级ID</span>
                <el-table-column label="班级Id" :show-overflow-tooltip="true" width="70">
                    <template #default="">
                        {{ showClassNum }}
                    </template>
                </el-table-column>
                <span class="textLabel">学生ID</span><el-table-column label="学生Id" prop="id" width="70"/>
                <span class="textLabel">学生学号</span><el-table-column label="学生学号" prop="username" width="150"/>
                <span class="textLabel">学生姓名</span><el-table-column label="学生姓名" prop="name" width="150"/>
                <!-- <span class="textLabel">学生性别</span><el-table-column label="学生性别" prop="sex" width="100">
                    <template #default="scope">
                        {{ scope.row.sex === 1?'男':'女' }}
                    </template>
                </el-table-column> -->
                <el-table-column align="right">
                <template #header>
                    <el-button size="small" @click="downStudent">下载学生信息到excel</el-button>
                </template>
                <template #default="scope">
                    <el-button size="small" @click="moveStudent(scope)"
                    >移除学生</el-button
                    >
                    <el-button size="small" @click="reStudentMessage(scope)"
                    >修改信息</el-button
                    >
                </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="totalWillShow_S" :default-page-size="10" v-model:current-page="currentPage_S"/>
        </el-dialog>

        <el-dialog v-model="changeStudentVisible" title="修改学生信息" width="800" :append-to-body=true @close="change_studentCancel">
            <span class="textLabel">学号</span><el-input type="text" v-model="change_studentUsername" placeholder="更改学号"/>
            <span class="textLabel">姓名</span><el-input type="text" v-model="change_studentName" placeholder="更改姓名"/>
            <span class="textLabel">性别</span><el-select placeholder="选择性别" v-model="change_studentSex">
                <el-option v-for="item in box_sex" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="change_studentCancel" class="buttomCancel">取消</div>
            <div @click="change_studentChange" class="buttomSubmit">修改</div></div>
        </el-dialog>

        <el-dialog v-model="addStudentWriteVisible" title="手动添加学生" width="800" :append-to-body=true @close="add_studentWriteCancel">
            <el-table :data="addStudent_write_studentList" style="width: 100%" max-height="250">
                <el-table-column prop="username" label="学号" show-overflow-tooltip=true width="300">
                    <template #default="scope">
                        <el-input type="text" v-model="scope.row.username"/>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名" show-overflow-tooltip=true width="300">
                    <template #default="scope">
                        <el-input type="text" v-model="scope.row.name"/>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="160">
                <template #default="scope">
                    <el-button
                    type="danger"
                    color="#e72b47"
                    size="small"
                    @click.prevent="deleteRow(scope.$index)"
                    style="--el-button-hover-text-color: #e72b47"
                    >
                    移除
                    </el-button>
                </template>
                </el-table-column>
            </el-table>
            <el-button class="mt-4" style="width: 100%" @click="onAddItem"
                >附加空项</el-button
            >
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="add_studentWriteCancel" class="buttomCancel">取消</div>
            <div @click="add_studentWriteChange" class="buttomSubmit">添加</div></div>
        </el-dialog>

        <el-dialog v-model="addStudentExcelVisible" title="Excel添加学生" width="800" :append-to-body=true>
            <span class="textLabel">Excel上传学生信息（请基于上传模板）</span>
            <el-upload
                class="upload-demo"
                drag
                @change="uploadFile"
                :auto-upload=false
                ref="myUpload"
                :limit="1"
                :on-exceed="handleExceed"
                @remove="removeFile"
            >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                拖拽 或 <em>点击上传文件</em>
                </div>
            </el-upload>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
        <div @click="add_studentExcelCancel" class="buttomCancel">取消</div>
        <div @click="add_studentExcelChange" class="buttomSubmit">添加</div></div>
    </el-dialog>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { AXIOS_URL, tranfer2Sheet, sheet2Array } from '../constants.js'
import {nanoid} from 'nanoid'
import axios from 'axios'
import { Buffer } from 'buffer';

export default {
    props:['classData','classTotal'],
    data(){
        return{
            searchName:'',
            dataWillShow:[],
            dataWillShow_org:[],
            dialogTableVisible: false,
            name:'',
            id:0,
            showClassNum:-1,
            totalWillShow:0,
            currentPage:1,
            totalWillShow_S:0,
            currentPage_S:1,
            addVisible: false,
            add_name:'',
            studentWillShow:[],
            studentVisible:false,
            changeStudentVisible:false,
            addStudentWriteVisible:false,
            addStudentExcelVisible:false,

            change_studentId:-1,
            change_studentUsername:'',
            change_studentName:'',
            change_studentSex:-1,
            box_sex:[{id:1,label:'男'},{id:0,label:'女'}],

            addStudent_write_classId:-1,
            addStudent_write_studentList:[{username: "",name: ""}],
            
            file_studentExcel_file:'',
            file_studentExcel_name:'',
            addExcelId:-1,
        }
    },
    computed:{
        slicedData() {
            const start = (this.currentPage - 1) * 10;
            const end = Math.min(this.currentPage * 10, this.dataWillShow.length);
            return this.dataWillShow.slice(start, end);
        }
    },
    mounted(){
    },
    watch: {
        classData: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.dataWillShow = newVal;
                this.dataWillShow_org = newVal;
                this.currentPage = 1
            }
        },
        classTotal: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.totalWillShow = newVal;
            }
        },
    },
    methods:{
        add_studentExcelChange(){
            var sheet = tranfer2Sheet(this.file_studentExcel_file,0);
            var result = sheet2Array(sheet,{name:"A",username:"B"},2);
            result.forEach(obj => {
                // 删除对象中的 gender 属性
                delete obj.gender;
            });
            axios.post(AXIOS_URL+'/admin/class/batchAddStudent',{id:this.addExcelId,clsStus:result})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '学生信息录入成功',
                        type: 'success',
                    })
                    this.getPageMessage(1)
                    this.addStudentExcelVisible = false
                    this.removeFile()
                }else{
                    ElMessage.error({
                        message: response.data.msg,
                    })
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '学生信息录入失败',
                })
            });
            // axios({
            //     method:'POST',
            //     url:AXIOS_URL+'/admin/class/excelImportStudent',
            //     headers:{
            //         'Content-Type': 'application/octet-stream'
            //     },
            //     params: {
            //         id:this.addExcelId,excelFile:this.file_studentExcel_file
            //     },
            // })
            // .then(response => {
            //     // 处理成功响应
            //     console.log(response);
            //     if(response.data.code===1){
            //         ElMessage({
            //             message: '信息修改成功',
            //             type: 'success',
            //         })
            //         this.getPageMessage(1)
            //         this.addStudentExcelVisible = false
            //     }
            // })
            // .catch((err) => {
            //     // 处理错误
            //     ElMessage.error({
            //         message: '信息修改失败',
            //     })
            //     console.log(err);
            // });
        },
        add_studentExcelCancel(){
            this.addStudentExcelVisible = false
            this.$refs['myUpload'].clearFiles()
            this.file_studentExcel_file = ''
            this.file_studentExcel_name = ''
        },
        handleExceed(files){
            let point = files[0]
            this.$refs['myUpload'].clearFiles()
            this.$refs['myUpload'].handleStart(point)
        },
        removeFile(){
                this.file_studentExcel_file = ''
                this.file_studentExcel_name = ''
        },
        uploadFile(e){
            if(!e.raw.type.includes('sheet')&&!e.raw.type.includes('excel')){
                ElMessage({
                    message: '请上传Excel表格文件',
                    type: 'warning',
                })
                this.$refs['myUpload'].clearFiles();
                this.file_studentExcel_file = ''
                this.file_studentExcel_name = ''
            }else{
                const reader = new FileReader();
                let buffer
        reader.onload = (event) => {
                buffer = Buffer.from(event.target.result);
            this.file_studentExcel_file = buffer;
            }
            this.file_studentExcel_name = nanoid() + '.' + e.name.split('.').pop();
            // this.file_studentExcel_file.file.raw.name = this.file_studentExcel_name;

        reader.readAsArrayBuffer(e.raw);
                // const blob = new Blob([e.raw], { type: e.raw.type });
                // this.file_studentExcel_file = blob;
                // this.file_studentExcel_name = nanoid()+'.'+e.name.split('.').pop();
                // this.file_studentExcel_file.file.raw.name = this.file_studentExcel_name;
            }
        },
        add_studentWriteChange(){
            // console.log(this.id);
            // console.log(JSON.stringify(this.addStudent_write_studentList));
            axios.post(AXIOS_URL+'/admin/class/batchAddStudent',{id:this.id,clsStus:this.addStudent_write_studentList})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '学生信息录入成功',
                        type: 'success',
                    })
                    this.getPageMessage(1)
                    this.addStudentWriteVisible = false
                }else{
                    ElMessage.error({
                        message: response.data.msg,
                    })
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '学生信息录入失败',
                })
            });
        },
        add_studentWriteCancel(){
            this.addStudent_write_studentList = [{username: "",name: ""}]
            this.addStudentWriteVisible = false
        },
        deleteRow(index){
            // console.log(this.addStudent_write_studentList);
            this.addStudent_write_studentList.splice(index, 1)
        },
        onAddItem(){
            this.addStudent_write_studentList.push({username: "",name: ""})
        },
        addExcel(scope){
            this.addExcelId = scope.row.id
            this.addStudentExcelVisible = true
        },
        addWrite(scope){
            this.id = scope.row.id
            this.addStudentWriteVisible = true
        },
        downStudent(){
            axios({
                method:'POST',
                url:AXIOS_URL+'/admin/class/excelExportStudent',
                headers:{
                    'Content-Type': 'application/json; charset=utf-8'
                },
                data: {
                    id: this.showClassNum,
                },
                responseType:'arraybuffer'
            })
            .then( res => {
                // console.log(res);
                let blob = new Blob([res.data],{type:'application/vnd.ms-excel;charset=utf-8'})
                const a= document.createElement('a');
                const url = URL.createObjectURL(blob);
                let timer = new Date();
                let timeText = timer.toLocaleString().replace('/','年').replace('/','月').replace(' ','日').replace(':','时').replace(':','分')+'秒'
                a.href = url;
                a.download =`班级数据_${timeText}.xls`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '班级数据下载失败',
                })
            });
        },
        moveStudent(scope){
            ElMessageBox.confirm(
                '确认从班级中删除该学生？',
                '删除确认',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                    distinguishCancelAndClose: true,
                }
            )
            .then(() => {
                axios.delete(`${AXIOS_URL}/admin/class/deleteStudent`, {
                    data: { 
                        id: scope.row.id
                    },
                    headers: {
                        'Content-Type': 'application/json' // 可选：设置请求的内容类型
                    }
                })
                .then(response => {
                    // 处理成功响应
                    if(response.data.code===1){
                        ElMessage({
                            message: '学生删除成功',
                            type: 'success',
                        })
                        this.getStudent(1,this.showClassNum)
                        this.getPageMessage(1)
                    }else{
                        ElMessage.error({
                            message: response.data.msg,
                        })
                }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '学生删除失败',
                    })
                });
            })
            .catch(() => {
            })
        },
        change_studentChange(){
            axios.put(AXIOS_URL+'/admin/class/updateStudent',{id:this.change_studentId,name:this.change_studentName,sex:this.change_studentSex,username:this.change_studentUsername},
                {headers:{
                    'Content-Type': 'application/json'
                }})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '学生信息修改成功',
                        type: 'success',
                    })
                    this.getPageMessage(1)
                    this.changeStudentVisible = false
                    this.studentVisible = false
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '学生信息修改失败',
                })
            });
        },
        change_studentCancel(){
            this.changeStudentVisible = false
        },
        reStudentMessage(scope){
            this.changeStudentVisible = true
            this.change_studentId = scope.row.id
            this.change_studentName = scope.row.name
            this.change_studentUsername = scope.row.username
            this.change_studentSex = scope.row.sex==='男'?1:0
        },
        add_cancel(){
            this.addVisible = false
            this.add_name=''
        },
        add_hange(){
            axios.post(AXIOS_URL+'/admin/class/add',{name:this.add_name})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '班级新增成功',
                        type: 'success',
                    })
                    this.getPageMessage(1)
                    this.addVisible = false
                    this.add_name=''
                }else{
                    ElMessage.error({
                        message: response.data.msg,
                    })
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '班级新增失败',
                })
            });
        },
        // changePage(e){
        //     this.getPageMessage(e);
        // },
        cancel(){
            this.dialogTableVisible  = false
        },
        getPageMessage(num){
            axios.post(AXIOS_URL+'/admin/class/pageSearch',{page:num,size:10,name:null})
                .then(response => {
                    // 处理成功响应
                    this.totalWillShow = response.data.data.total
                    this.dataWillShow = response.data.data.records
                    this.dataWillShow_org = response.data.data.records
                    var cnum = 2
                    while(cnum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/class/pageSearch',{page:cnum,size:10,name:null})
                        .then(response => {
                            this.dataWillShow = response.data.data.records.concat(this.dataWillShow)
                            this.dataWillShow_org = response.data.data.records.concat(this.dataWillShow_org)
                        })
                        cnum+=1
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
        },
        change(){
            axios.put(AXIOS_URL+'/admin/class/update',{id:this.id,name:this.name})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '信息修改成功',
                        type: 'success',
                    })
                    this.getPageMessage(1)
                    this.dialogTableVisible = false
                }else{
                    ElMessage.error({
                        message: response.data.msg,
                    })
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '信息修改失败',
                })
            });
        },
        searchByName(){
            this.dataWillShow = this.dataWillShow_org.filter(item => item.name.includes(this.searchName))
        },
        showStudent(scope){
            this.showClassNum = scope.row.id
            this.getStudent(1,scope.row.id)
            this.studentVisible = true
        },
        getStudent(num,id){
            axios.post(AXIOS_URL+'/admin/class/pageSearchStudent',{id:id,page:num,size:10})
                .then(response => {
                    // console.log(response);
                    // 处理成功响应
                    this.totalWillShow_S = response.data.data.total
                    this.studentWillShow = response.data.data.records
                    var snum = 2
                    while(snum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/class/pageSearchStudent',{id:id,page:snum,size:10})
                        .then(response => {
                            this.studentWillShow = response.data.data.records.concat(this.studentWillShow)
                            this.studentWillShow.sort((a, b) => {
                                return a.id - b.id; // 按 id 升序排序
                            });
                        })
                        snum+=1
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '学生获取数据失败',
                    })
                });
        },
        reMessage(scope){
            this.id = scope.row.id
            this.name = scope.row.name
            this.dialogTableVisible = true
        }
    }
}
</script>

<style scoped>
.hoverStep{
    color: #ffffff;
    transition: .3s ease;
}
.hoverStep:hover{
    color: #e72b47;
}
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px 15px 50px 15px;
    height: auto;
}
.messageBox{
    margin: 25px 60px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 15px;
}
</style>