<template>
    <div class="cardBox">
        <div class="messageBox">
            <h3>题库管理</h3>
            <div @click="add()" class="buttomTop">新增题目</div>
            <div class="searchBox">
                <p>具体条件搜索</p>
                <div>
                    <el-input
                        v-model="search_title"
                        style="width: 680px"
                        placeholder="选填题干"
                        clearable
                        size="small"
                    />
                    <div style="display: flex;justify-content: space-between; margin-top: 3px;">
                        <el-input
                            v-model="search_score"
                            style="width: 80px"
                            placeholder="选填分数"
                            clearable
                            size="small"
                        />
                        <el-select v-model="search_difficulty" placeholder="选填难度" style="width: 100px" size="small" clearable>
                            <el-option
                                v-for="item in box_difficulty"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                            />
                        </el-select>
                        <el-select v-model="search_questionType" placeholder="选填题型" style="width: 100px" size="small" clearable>
                            <el-option
                                v-for="item in box_type"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                            />
                        </el-select>
                        <el-select v-model="search_changed" placeholder="选填状态" style="width: 100px" size="small" clearable>
                            <el-option
                                v-for="item in box_changed"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                            />
                        </el-select>
                        <el-tree-select placeholder="选填知识点" v-model="search_knowledge" multiple :data="box_knowledge" check-strictly=true :render-after-expand="false" :value-key="'id'" size="small" style="width:250px;">
                        </el-tree-select>
                    </div>
                </div>
                <div class="searchBoxdiv" @click="search">搜索</div>
            </div>
            <el-table :data="slicedData" style="width: 100%">
                <el-table-column label="Id" prop="id" :show-overflow-tooltip=true width="70px"/>
                <el-table-column label="题干" :show-overflow-tooltip=true width="340px">
                    <template #default="scope">
                        <EditorMathText :editorText=scope.row.title :isPointer="false"/>
                    </template>
                </el-table-column>
                <el-table-column label="题型" :show-overflow-tooltip=true width="100px">
                    <template #default="scope">
                        {{ scope.row.questionType===0?'单选题':scope.row.questionType===1?'多选题':'判断题' }}
                    </template>
                </el-table-column>
                <el-table-column label="分值" prop="score" :show-overflow-tooltip=true width="80px"/>
                <el-table-column label="难度" :show-overflow-tooltip=true width="80px">
                    <template #default="scope">
                        {{ scope.row.difficulty===0?'简单':scope.row.difficulty===1?'一般':scope.row.difficulty===2?'中等':scope.row.difficulty===3?'困难':'特困' }}
                    </template>
                </el-table-column>
                <el-table-column align="right" width="150px">
                <template #header>
                    <div>筛选题目</div>
                </template>
                <template #default="scope">
                    <el-button size="small" @click="deleteTopic(scope)" :disabled="!scope.row.changed"
                    >删除题目</el-button
                    >
                    <el-button size="small" @click="reTopicMessage(scope, true)" :disabled="!scope.row.changed"
                    >编辑题目</el-button
                    >
                    <el-button size="small" @click="reTopicMessage(scope, false)"
                    >查看题目</el-button
                    >
                </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="totalWillShow" :default-page-size="10" v-model:current-page="currentPage"/>
        </div>

        <el-dialog v-model="dialogTableVisible" :title="isChange?'编辑题目信息':'查看题目详情'" width="800" :append-to-body=true @close="cancel">
            <el-scrollbar max-height="400px" style="padding-right: 15px;">
            <span class="textLabel">题干</span>
            <editor-content :editor="editor" class="editor"/>
            <!-- <EditorMathText :editorText="editor" :isPointer="true"/> -->
            <!-- <el-input cols="30" rows="5" placeholder="题干" v-model="title" type="textarea" :disabled="!isChange"></el-input> -->
            <span class="textLabel">题型</span><el-select placeholder="选择题型" v-model="questionType"  :disabled="!isChange">
                <el-option v-for="item in box_type" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
            <span class="textLabel">选项</span>
            <div v-if="questionType===0||questionType===1">
                <el-input
                    v-model="options_A"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                    :disabled="!isChange"
                >
                    <template #prepend>选项A</template>
                </el-input>
                <el-input
                    v-model="options_B"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                    :disabled="!isChange"
                >
                    <template #prepend>选项B</template>
                </el-input>
                <el-input
                    v-model="options_C"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                    :disabled="!isChange"
                >
                    <template #prepend>选项C</template>
                </el-input>
                <el-input
                    v-model="options_D"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                    :disabled="!isChange"
                >
                    <template #prepend>选项D</template>
                </el-input>
            </div>
            <div v-else-if="questionType===2">
                <el-input
                    v-model="options_A"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                    :disabled="!isChange"
                >
                    <template #prepend>选项A</template>
                </el-input>
                <el-input
                    v-model="options_B"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                    :disabled="!isChange"
                >
                    <template #prepend>选项B</template>
                </el-input>
            </div>
            <span class="textLabel">题目答案（多选题按字母顺序大写输入，例如：ABD）</span><el-input type="text" placeholder="答案" v-model="answer"  :disabled="!isChange"/>
            <span class="textLabel">题目分值</span><el-input-number v-model="score" :step="5" :disabled="!isChange"/>
            <span class="textLabel">知识点</span><el-tree-select placeholder="选择知识点" v-model="knowledge" multiple :data="box_knowledge" check-strictly=true :render-after-expand="false" :value-key="'id'"  :disabled="!isChange"></el-tree-select>
            <span class="textLabel">题目难度</span>
            <el-select v-model="difficulty" placeholder="选填难度" style="width: 100px" clearable  :disabled="!isChange">
                <el-option
                    style="font-size: 13px;"
                    v-for="item in box_difficulty"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                />
            </el-select>
            </el-scrollbar>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="cancel" class="buttomCancel">取消</div>
            <div @click="change" class="buttomSubmit" v-if="isChange">修改</div></div>
        </el-dialog>

        <el-dialog v-model="addTopicVisible" title="创建新题目" width="800" :append-to-body=true destroy-on-close=true @close="add_cancel">
            <el-scrollbar max-height="400px" style="padding-right: 15px;">
            <!-- <MathTopic :add_title="add_title"/> -->
            <span class="textLabel">题干</span>

            <editor-content :editor="editor" class="editor"/>
            <!-- <el-input cols="30" rows="5" placeholder="题干" v-model="add_title" type="textarea" aria-placeholder="添加题干文本"></el-input> -->

            
            <span class="textLabel">题型</span><el-select placeholder="选择题型" v-model="add_questionType">
                <el-option v-for="item in box_type" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
            <span class="textLabel">选项</span>
            <div v-if="add_questionType===0||add_questionType===1">
                <el-input
                    v-model="add_options_A"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                >
                    <template #prepend>选项A</template>
                </el-input>
                <el-input
                    v-model="add_options_B"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                >
                    <template #prepend>选项B</template>
                </el-input>
                <el-input
                    v-model="add_options_C"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                >
                    <template #prepend>选项C</template>
                </el-input>
                <el-input
                    v-model="add_options_D"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                >
                    <template #prepend>选项D</template>
                </el-input>
            </div>
            <div v-else-if="add_questionType===2">
                <el-input
                    v-model="add_options_A"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                >
                    <template #prepend>选项A</template>
                </el-input>
                <el-input
                    v-model="add_options_B"
                    style="max-width: 600px;margin-bottom: 2px;"
                    placeholder="输入选项内容"
                >
                    <template #prepend>选项B</template>
                </el-input>
            </div>
            <span class="textLabel">题目答案（多选题按字母顺序大写输入，例如：ABD）</span><el-input type="text" placeholder="添加答案" v-model="add_answer"/>
            <span class="textLabel">题目分值</span><el-input-number v-model="add_score" :step="5" />
            <span class="textLabel">知识点</span><el-tree-select placeholder="选择知识点" v-model="add_knowledge" multiple :data="box_knowledge" check-strictly=true :render-after-expand="false" :value-key="'id'"></el-tree-select>
            <span class="textLabel">题目难度</span>
            <el-select v-model="add_difficulty" placeholder="选填难度" style="width: 100px" clearable>
                <el-option
                    v-for="item in box_difficulty"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                />
            </el-select>
            </el-scrollbar>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="add_cancel" class="buttomCancel">取消</div>
            <div @click="add_change" class="buttomSubmit">新增</div></div>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { AXIOS_URL } from '../constants.js'
import axios from 'axios'

import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import EditorMathText from './EditorMathText.vue'
import { MathExtension } from "@aarkue/tiptap-math-extension";
import "katex/dist/katex.min.css";
// import MathTopic from './MathTopic.vue'

export default {
    components: {
        EditorContent, EditorMathText,
    },

    beforeUnmount() {
        this.editor.destroy()
    },

    props:['topicData','topicTotal'],
    data(){
        return{

            editor: null,
            isEditable: true,

            searchName:'',
            dataWillShow:[],
            // dataWillShow_org:[],
            dialogTableVisible: false,
            title:'',
            options:'',
            answer:'',
            questionType:'',
            knowledge:[],
            score:5,
            difficulty:'',
            options_A:'',
            options_B:'',
            options_C:'',
            options_D:'',
            id:0,
            totalWillShow:0,
            currentPage:1,
            addTopicVisible: false,
            add_title:'',
            add_answer:'',
            add_questionType:'',
            add_knowledge:[],
            add_score:5,
            add_difficulty:'',
            add_options_A:'',
            add_options_B:'',
            add_options_C:'',
            add_options_D:'',

            box_type:[{id:0,label:'单选题'},{id:1,label:'多选题'},{id:2,label:'判断题'}],
            box_knowledge:[],
            box_difficulty:[{id:0,label:'简单'},{id:1,label:'一般'},{id:2,label:'中等'},{id:3,label:'困难'},{id:4,label:'特困'}],
            box_changed:[{id:1,label:'可修改'},{id:0,label:'不可修改'}],

            search_changed:null,
            search_knowledge:null,
            search_questionType:null,
            search_difficulty:null,
            search_score:null,
            search_title:null,

            isChange: false,
        }
    },
    computed:{
        
        slicedData() {
            const start = (this.currentPage - 1) * 10;
            const end = Math.min(this.currentPage * 10, this.dataWillShow.length);
            return this.dataWillShow.slice(start, end);
        },
        add_options() {
            const options = {
                'A': this.add_options_A,
                'B': this.add_options_B,
                'C': this.add_options_C,
                'D': this.add_options_D
            };
            const result = {};
            for (const [key, value] of Object.entries(options)) {
                if (value !== '') {
                    result[key] = value;
                }
            }
            return JSON.stringify(result);
        },
        options_org() {
            const options = {
                'A': this.options_A,
                'B': this.options_B,
                'C': this.options_C,
                'D': this.options_D
            };
            const result = {};
            for (const [key, value] of Object.entries(options)) {
                if (value !== '') {
                    result[key] = value;
                }
            }
            return JSON.stringify(result);
        }
    },
    mounted(){
        this.editor = new Editor({
        extensions: [
            StarterKit,
            MathExtension.configure({ evaluation: true, katexOptions: { macros: { "\\B": "\\mathbb{B}" } }, delimiters: "dollar", readOnly: true }),
        ],
        content: ``,
        });
        this.editor.commands.insertContent(JSON.parse('[{"type":"text","text":"输入 "},{"type":"inlineMath","attrs":{"latex":"\\\\LaTeX","evaluate":"no","display":"no"}},{"type":"text","text":" 语句表达数学公式"}]'));

        axios.get(AXIOS_URL+'/admin/selectBox/knowledgeTree')
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    this.box_knowledge = response.data.data
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '知识点下拉框获取失败',
                })
            });
    },
    watch: {
        topicData: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.dataWillShow = newVal;
                // this.dataWillShow_org = newVal;
            }
        },
        topicTotal: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.totalWillShow = newVal;
            }
        },
    },
    methods:{
        jsonToString(json) {
            let result = '';
            
            json.content.forEach(paragraph => {
                if (paragraph.type === 'paragraph' && paragraph.content) {
                    paragraph.content.forEach(item => {
                        if (item.type === 'text') {
                            result += item.text;
                        } else if (item.type === 'inlineMath' && item.attrs && item.attrs.latex) {
                            result += item.attrs.latex;
                        }
                    });
                }
                result += '\n';  // Add newline after each paragraph
            });

            return result.trim();  // Remove the last newline character
        },
        search(){
            // console.log(this.search_title===''?null:this.search_title,this.search_score===''?null:this.search_score,this.search_difficulty===''?null:this.search_difficulty,this.search_questionType===''?null:this.search_questionType,this.search_knowledge.length===0?null:this.search_knowledge,this.search_changed===''?null:this.search_changed)
            this.getPageMessage(this.search_title===''?null:this.search_title,this.search_score===''?null:this.search_score,this.search_difficulty===''?null:this.search_difficulty,this.search_questionType===''?null:this.search_questionType,this.search_knowledge.length===0?null:this.search_knowledge,this.search_changed===''?null:this.search_changed)
        },
        add(){
            axios.get(AXIOS_URL+'/admin/selectBox/knowledgeTree')
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    this.editor.commands.setContent(JSON.parse('[{"type":"text","text":"输入 "},{"type":"inlineMath","attrs":{"latex":"\\\\LaTeX","evaluate":"no","display":"no"}},{"type":"text","text":" 语句表达数学公式"}]'));
                    this.box_knowledge = response.data.data
                    this.addTopicVisible=true
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '知识点下拉框获取失败',
                })
            });
        },
        add_cancel(){
            // console.log(JSON.stringify(this.editor.getJSON()).replaceAll('\\','\\\\'));
            this.editor.commands.setContent(JSON.parse('[{"type":"text","text":"输入 "},{"type":"inlineMath","attrs":{"latex":"\\\\LaTeX","evaluate":"no","display":"no"}},{"type":"text","text":" 语句表达数学公式"}]'));
            
            this.addTopicVisible = false
            this.add_title=''
            this.add_options=''
            this.add_answer=''
            this.add_questionType=''
            this.add_knowledge=[]
            this.add_score=5
            this.add_difficulty=0
        },
        add_change(){
            // console.log(this.add_title,this.add_options,this.add_answer,this.add_questionType,this.add_knowledge,this.add_score,this.add_difficulty);
            axios.post(AXIOS_URL+'/admin/question/add',{title:JSON.stringify(this.editor.getJSON()).replaceAll('\\','\\\\'),options:this.add_options,answer:this.add_answer,questionType:this.add_questionType,knowledge:this.add_knowledge,score:this.add_score,difficulty:this.add_difficulty})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '题目新增成功',
                        type: 'success',
                    })
                    this.getPageMessage(null,null,null,null,null,null)
                    this.addTopicVisible = false
                }else{
                    ElMessage.error({
                        message: response.data.msg,
                    })
                }
            })
            .catch(() => {
                // 处理错误
                // console.log(err);
                ElMessage.error({
                    message: '题目新增失败',
                })
            });
        },
        cancel(){
            this.dialogTableVisible  =false
        },
        getPageMessage(title,score,difficulty,questionType,knowledge,changed){
            axios.post(AXIOS_URL+'/admin/question/pageSearch',{page:1,size:10,title,score,difficulty,questionType,knowledge,changed})
            .then(response => {
                // 处理成功响应
                this.totalWillShow = response.data.data.total
                this.dataWillShow = response.data.data.records
                // this.dataWillShow_org = this.dataWillShow
                var unum = 2
                while(unum<=response.data.data.pages){
                    axios.post(AXIOS_URL+'/admin/question/pageSearch',{page:unum,size:10,title,score,difficulty,questionType,knowledge,changed})
                    .then(res => {
                        this.dataWillShow = this.dataWillShow.concat(res.data.data.records)
                    })
                    unum+=1
                }
                // this.dataWillShow_org = this.dataWillShow
                ElMessage({
                    message: '题目列表获取成功',
                    type: 'success',
                })
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '获取数据失败',
                })
            });
        },
        change(){
            axios.put(AXIOS_URL+'/admin/question/update',{id:this.id,title:JSON.stringify(this.editor.getJSON()).replaceAll('\\','\\\\'),options:this.options_org,answer:this.answer,questionType:this.questionType,knowledge:this.knowledge.map(num => String(num)),score:this.score,difficulty:this.difficulty}, {
                headers: {
                    'Content-Type': 'application/json' // 可选：设置请求的内容类型
                }
            })
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '信息修改成功',
                        type: 'success',
                    })
                    this.getPageMessage(null,null,null,null,null,null)
                    this.currentPage = 1
                    this.dialogTableVisible = false
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '信息修改失败',
                })
            });
        },
        deleteTopic(scope){
            ElMessageBox.confirm(
                '确认删除该题目？',
                '删除确认',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                    distinguishCancelAndClose: true,
                }
            )
            .then(() => {
                axios.delete(`${AXIOS_URL}/admin/question/delete`, {
                    data: { id: scope.row.id },
                    headers: {
                        'Content-Type': 'application/json' // 可选：设置请求的内容类型
                    }
                })
                .then(response => {
                    // 处理成功响应
                    if(response.data.code===1){
                        ElMessage({
                            message: '题目删除成功',
                            type: 'success',
                        })
                        this.getPageMessage(null,null,null,null,null,null)
                    }else{
                        ElMessage.error({
                            message: response.data.msg,
                        })
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '题目删除失败',
                    })
                });
            })
            .catch(() => {
            })
        },
        reTopicMessage(scope, isChange){
            this.isChange = isChange
            axios.get(AXIOS_URL+'/admin/selectBox/knowledgeTree')
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    this.box_knowledge = response.data.data
                    axios({
                        method: 'GET',
                        url: AXIOS_URL+'/admin/question/get',
                        params:{
                            id: scope.row.id
                        },
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        // 处理成功响应
                        if(response.data.code===1){
                            this.editor.commands.setContent(JSON.parse(response.data.data.title));

                            // this.title = response.data.data.title
                            const options = JSON.parse(response.data.data.options);
                            if ("A" in options) {
                                this.options_A = options["A"];
                            }
                            if ("B" in options) {
                                this.options_B = options["B"];
                            }
                            if ("C" in options) {
                                this.options_C = options["C"];
                            }
                            if ("D" in options) {
                                this.options_D = options["D"];
                            }
                            this.answer = response.data.data.answer
                            this.questionType = response.data.data.questionType
                            this.knowledge = response.data.data.knowledge.map(str => parseInt(str, 10));
                            this.score = response.data.data.score
                            this.difficulty = response.data.data.difficulty
                            this.id = response.data.data.id
                            this.dialogTableVisible = true
                        }
                    })
                    .catch(() => {
                        // 处理错误
                        ElMessage.error({
                            message: '题目具体信息获取失败',
                        })
                    });
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '知识点下拉框获取失败',
                })
            });
        }
    },
    // components:{
    //     MathTopic
    // }
}
</script>

<style scoped>
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px 15px 50px 15px;
    height: auto;
}
.messageBox{
    margin: 25px 30px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 15px;
}
.searchBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    margin-bottom: 5px;
    box-sizing: border-box;
    overflow: hidden;
    border: #e72b47 1px solid;
    border-radius: 30px;
    background-color: #ffecec;
}
.searchBox p{
    font-size: 13px;
    color: #e72b47;
    font-weight: 600;
    padding-left: 20px;
}
.searchBoxdiv{
    background-color: #e72b47;
    height: 60px;
    width: 55px;
    text-align: center;
    line-height: 60px;
    font-size: 13px;
    color: #ffecec;
    cursor: pointer;
}
</style>
<style>
.ProseMirror {
    padding: 5px 11px;
    min-height: 24px;
    line-height: 24px;
    border-radius: 4px;
    border: 1px solid #dcdfe6 !important;
    transition: .3s ease;
    outline: none;
}
.ProseMirror:hover{
  border: 1px solid #c0c4cc;
}
.ProseMirror-focused{
    border-color: #e72b47 !important;
}
.tiptap-math.result {
  box-sizing: border-box;
}
</style>