<template>
    <div class="cardBox">
        <div class="messageBox">
            <h3>个人信息</h3>
            <div>
                <el-tag type="danger" class="messageName">用户名</el-tag>
                <p>{{ username }}</p>
            </div>
            <div>
                <el-tag type="danger">姓名</el-tag>
                <p>{{ name }}</p>
            </div>
            <div>
                <el-tag type="danger">性别</el-tag>
                <p>{{ sex === 0 ? '女' : sex === 1 ?'男':'未知' }}</p>
            </div>
            <div>
                <el-button color="#e72b47" :dark="isDark" size="small" style="--el-button-hover-text-color: #e72b47" @click="changeMessage">更改性别</el-button>
                <el-button color="#e72b47" :dark="isDark" size="small" style="--el-button-hover-text-color: #e72b47" @click="changePwd">更改密码</el-button>
            </div>
            <el-dialog v-model="changeMessageVisible" title="修改用户信息" width="500" :append-to-body=true>
                <span class="textLabel">性别</span><el-select placeholder="选择性别" v-model="sex_change">
                    <el-option v-for="item in box_sex" :key="item.id" :label="item.label" :value="item.id" />
                </el-select>
                <div style="display: flex;justify-content: right; margin-top: 15px;">
                    <div @click="cancel" class="buttomCancel">取消</div>
                    <div @click="change" class="buttomSubmit">修改</div>
                </div>
            </el-dialog>
            <el-dialog v-model="changePwdVisible" title="修改账号密码" width="500" :append-to-body=true>
                <span class="textLabel">原密码</span><el-input type="password" show-password v-model="pwd_org" placeholder="输入原密码"/>
                <span class="textLabel">新密码</span><el-input type="password" show-password v-model="pwd_new" placeholder="输入新密码"/>

                <div style="display: flex;justify-content: right; margin-top: 15px;">
                    <div @click="cancel_pwd" class="buttomCancel">取消</div>
                    <div @click="change_pwd" class="buttomSubmit">修改</div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { logout, AXIOS_URL } from '../constants.js'
import axios from 'axios'

export default {
    data(){
        return{
            studentMessage_data : {},
            username: '',
            name: '',
            sex: 0,
            sex_change: 0,
            changeMessageVisible:false,
            changePwdVisible:false,
            box_sex:[{id:0,label:'女'},{id:1,label:'男'},{id:2,label:'未知'}],
            pwd_org:'',
            pwd_new:'',
        }
    },
    mounted(){
        this.getStudentMessage()
    },
    methods:{
        getStudentMessage(){
            axios.get(AXIOS_URL+'/common/account/getInfo')
            .then(response => {
                // console.log(response);
                // 处理成功响应
                if(response.data.code===1){
                    this.username = response.data.data.username
                    this.name = response.data.data.name
                    this.sex = response.data.data.sex
                }else{
                    ElMessage.error({
                        message: response.data.msg,
                    })
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '用户数据获取失败',
                })
            });  
        },
        changeMessage(){
            this.sex_change = this.sex
            this.changeMessageVisible = true
        },
        cancel(){
            this.changeMessageVisible = false
        },
        change(){
            console.log(this.sex_change);
            axios.put(AXIOS_URL+'/common/account/editInfo',{sex:this.sex_change})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '信息更改成功',
                        type: 'success',
                    })
                    this.getStudentMessage()
                    this.changeMessageVisible = false
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '信息更改失败',
                })
            });
        },
        
        changePwd(){
            this.changePwdVisible = true
            this.pwd_new = ''
            this.pwd_org = ''
        },
        cancel_pwd(){
            this.pwd_new = ''
            this.pwd_org = ''
        },
        change_pwd(){
            axios.put(AXIOS_URL+'/common/account/changePwd',{newPwd:this.pwd_new,oldPwd:this.pwd_org})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '密码更改成功，请重新登陆',
                        type: 'success',
                    })
                    this.changePwdVisible = false
                    setTimeout(()=>{
                        logout()
                    },3000)
                }
            })
            .catch((err) => {
                // 处理错误
                ElMessage.error({
                    message: `密码更改失败，${err.response.data.msg}`,
                })
            });
        },
    }
}
</script>

<style scoped>
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
    height: 101vh;
}
.messageBox{
    margin: 25px 60px;
}
.messageBox div{
    margin-left: 10px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 35px;
}
.messageBox p{
    margin: 0 15px;
    color: #141414;
}
</style>