<template>
    <div class="cardBox">
        <div class="messageBox" style="z-index: 9;" v-if="!isshowcourse">
            <h3>考试管理</h3>
            <el-table :data="paperData_" stripe style="width: 100%">
                <el-table-column prop="id" label="id" width="80" />
                <el-table-column prop="title" label="考试名" width="250" />
                <el-table-column prop="startAt" label="开始时间" width="200" />
                <el-table-column prop="endAt" label="结束时间" width="200" />
                <el-table-column label="进入考试" width="180">
                    <template #default="scope">
                        <el-button
                            class="btn"
                            size="small"
                            @click.prevent="into(scope,timeDifferences[scope.$index])"
                            style="--el-button-hover-text-color: #e72b47"
                            :disabled="timeDifferences[scope.$index]==='开始答题'?false:timeDifferences[scope.$index]==='查看答案'?false:true"
                        >
                            {{ timeDifferences[scope.$index] }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    
        <div class="messageBox" style="z-index: 10;" v-else-if="isshowcourse">
            <div style="position: absolute; top:-8px;right: 0;z-index: 999;text-align: center;opacity: .6;width: 70px; height: 35px;" v-if="isshowcourse"><p style="font-size: 12px;margin-bottom: 4px;">倒计时</p><b>{{ daojishi_text }}</b></div>
            <h3 style="display: flex;align-items: center;"><Back style="height: 18px;margin-right: 8px;cursor: pointer;" @click="back"/>{{ courseData.title }}</h3>
            <el-scrollbar height="100vh">
                <div style="display: flex;">
                    <p style="margin-right: 15px;font-size: 12px;opacity: .5;">总分：{{ courseData.paperScore }}</p>
                    <p style="font-size: 12px;opacity: .5;">作答截止时间：{{ courseData.endAt }}</p>
                </div>
                <div style="margin-top: 10px;">
                    <!-- {{ userAnswerBox.contents }} -->
                    <div v-for="(kind, index1) in courseData.contents" :key="index1" style="margin-bottom: 10px">
                        <p style="line-height: 35px;font-weight: 600;font-size: 16px;">{{index1 === 0 ? '一，' : index1 === 1 ? '二，' : '三，'}}{{box_type[kind.questionType] }}（{{ kind.localScore }}分 共{{ kind.questionNum }}题）</p>
                            <!-- {{ kind.questionType }} -->
                        <div v-for="(item, index2) in kind.questions" :key="index2" style="margin: 7px 18px;">
                            <p style="margin-bottom: 3px;">{{ index2+1 }}，{{ item.questionTitle }}</p>
                            <el-radio-group v-model="userAnswerBox.contents[index1][index2].userAnswer" style="width: 80%;margin-left: 25px;margin-bottom: 15px;margin-top: 5px;" v-if="kind.questionType!==1">
                                <el-radio
                                    v-for="(value, key) in JSON.parse(item.questionOptions)"
                                    :key="key"
                                    :label="key"
                                    :value="value"
                                    style="display: inline-block; width: 100%;line-height: 35px;"
                                    :disabled="state===0?false:true"
                                >
                                    {{ key }}，{{ value }}
                                </el-radio>
                            </el-radio-group>
                            <el-checkbox-group v-model="userAnswerBox.contents[index1][index2].userAnswer" style="width: 80%; margin-left: 25px; margin-bottom: 15px; margin-top: 5px;"  v-if="kind.questionType===1">
                                <el-checkbox
                                    v-for="(value, key) in JSON.parse(item.questionOptions)"
                                    :key="key"
                                    :label="key"
                                    :value="value"
                                    style="display: inline-block; width: 100%; line-height: 35px;"
                                    :disabled="state === 0 ? false : true"
                                >
                                    {{ key }}，{{ value }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <el-button type="danger" :icon="Delete" class="btn" color="#e72b47" style="width: 4vw; position: fixed; right: 50px; bottom: 50px; z-index: 999;" v-if="isshowcourse&&(state===0)" @click="dialogVisible=true">交卷</el-button>

        <el-dialog
            v-model="dialogVisible"
            title="交卷确认"
            width="400"
            append-to-body
        >
            你 <b>{{userAnswerBox.contents.every(item => item.every(item2=>item2.userAnswer !== ""))?'已经':'还未'}}</b> 作答完所有题目，是否交卷？
            <div style="display: flex;justify-content: right; margin-top: 15px;">
                <div @click="dialogVisible=false" class="buttomCancel">取消</div>
                <div @click="push_change" class="buttomSubmit">确认</div>
            </div>
        </el-dialog>

        <el-dialog
            v-model="isshowpaperFinish"
            title="试卷详情"
            width="1000"
            append-to-body
        >
            <PaperAfter :paperFinishData="paperFinishData" :isS="true"/>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { AXIOS_URL } from '../constants.js'
import PaperAfter from './PaperAfter.vue'

export default {
    props: ['paperData'],
    data() {
        return {
            paperData_: [],
            timeDifferences: [],
            caninto:[],
            courseData:[],
            isshowcourse:false,
            box_type:['单选题','多选题','判断题'],
            checkboxGroup1:[],
            userAnswerBox:{},
            dialogVisible:false,
            daojishi_inti_key:null,
            daojishi_text:'',
            state:-1,
            isshowpaperFinish:false,
            paperFinishData:{},
        }
    },
    watch: {
        paperData: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.paperData_ = newVal;
            }
        },
    },
    methods: {
        getPaperList(){
            axios.post(AXIOS_URL+'/student/paper/pageSearch',{page:1,size:10,userScore:null,state:null,paperAt:null,paperDifficulty:null,paperTitle:null})
                .then(response => {
                    // 处理成功响应
                    // console.log(response);
                    this.paperData_ = response.data.data.records
                    var pnum = 2
                    while(pnum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/student/paper/pageSearch',{page:pnum,size:10,userScore:null,state:null,paperAt:null,paperDifficulty:null,paperTitle:null})
                        .then(response => {
                            this.paperData_ = this.paperData_.concat(response.data.data.records)
                        })
                        pnum+=1
                    }
                })
                .catch(() => {
                    ElMessage.error({
                        message: '考试数据获取失败',
                    })
                });
        },
        back(){
            if(this.state===0){
                const confirmation = confirm("答题中途返回游览器将不会保留已作答的内容，仍要返回吗？");
                if (confirmation) {
                    this.isshowcourse = false
                    clearInterval(this.daojishi_inti_key)
                }
            }else{
                this.isshowcourse = false
                clearInterval(this.daojishi_inti_key)
            }
        },
        push_change(){
            this.userAnswerBox.contents = this.userAnswerBox.contents.flat()
            this.userAnswerBox.contents.forEach(item => {
                if (Array.isArray(item.userAnswer)) {
                    if (item.userAnswer.length === 0) {
                        item.userAnswer = "";
                    } else {
                        item.userAnswer = item.userAnswer.sort().join('');
                    }
                }
            })
            axios.put(AXIOS_URL+'/student/paper/submit', {
                id: this.userAnswerBox.id.row.id,
                contents: this.userAnswerBox.contents
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res)=>{
                // console.log(res);
                if(res.data.code===1){
                    ElMessage({
                        message: '试卷提交成功',
                        type: 'success',
                    })
                    clearInterval(this.daojishi_inti_key)
                    this.isshowcourse = false
                    this.getPaperList()
                    this.dialogVisible = false
                }else{
                    ElMessage.error({
                        message: res.data.msg,
                    })
                }
            })
            .catch(()=>{
                // console.log(err);
                ElMessage.error({
                    message: '试卷提交失败，请联系老师',
                })
            })
        },
        updateAnswerById(id, answer) {
            for (let i = 0; i < this.userAnswerBox.length; i++) {
                if (this.userAnswerBox[i].id === id) {
                    this.userAnswerBox[i].contents.forEach(content => {
                        if (content.id === id) {
                            content.userAnswer = answer;
                        }
                    });
                    break;
                }
            }
        },
        into(scope,stateText){
            this.state = scope.row.state;
            if(this.state === 0&&stateText==="开始答题"){
                axios({
                    method: 'GET',
                    url: AXIOS_URL+'/student/paper/getNotFinishedPaper',
                    params:{
                        id: scope.row.id
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(res => {
                    if(res.data.code===1){
                        this.courseData = res.data.data
                        this.isshowcourse = true
                        this.daojishi_inti(res.data.data.endAt)
                        
                        this.userAnswerBox = {
                            "id": scope,
                            "contents": this.courseData.contents.map(content => {
                                return content.questions.map(question => {
                                    return { "id": question.questionId, "userAnswer": [], "userScore": 0 };
                                });
                            })
                        }
                        ElMessage({
                            message: '试卷题目加载成功',
                            type: 'success',
                        })
                    }else{
                        ElMessage.error({
                            message: res.data.msg,
                        })
                    }
                })
                .catch(()=>{
                    // console.log(err);
                    ElMessage.error({
                        message: '试卷题目获取失败',
                    })
                })
            }else if(this.state === 2&&stateText==="查看答案"){
                axios({
                    method: 'GET',
                    url: AXIOS_URL+'/student/paper/getFinishedPaper',
                    params:{
                        id: scope.row.id
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(res => {
                    if(res.data.code===1){
                        this.paperFinishData = res.data.data
                        this.isshowpaperFinish = true
                        ElMessage({
                            message: '考试结果获取成功',
                            type: 'success',
                        })
                    }else{
                        ElMessage.error({
                            message: res.data.msg,
                        })
                    }
                })
                .catch(()=>{
                    // console.log(err);
                    ElMessage.error({
                        message: '考试结果获取失败',
                    })
                })
            }
        },
        calculateTimeStatus(startAt, endAt, state) {
            const now = new Date();
            const start = new Date(startAt);
            const end = new Date(endAt);
            
            if (now < start) {
                return this.calculateTimeDifference(startAt); // 返回距离开始时间的差
            } else if (now >= start && now <= end) {
                if(state===0){
                    return "开始答题"; // 现在在开始和结束之间
                }else{
                    return "请等待考试结束"
                }
            } else {
                if(state===1)
                    return "待批阅"; // 现在在结束时间之后
                else if(state===2)
                    return "查看答案"
                else if(state===0)
                    return "试卷未交"
            }
        },
        padZero(num){
            return num < 10 ? '0' + num : num;
        },
        calculateTimeDifference(targetDate) {
            const now = new Date();
            const target = new Date(targetDate);
            
            const diffMs = target - now; // Difference in milliseconds
            if (diffMs < 0) {
                return "开始答题";
            }
            const diffSeconds = Math.floor((diffMs / 1000) % 60);
            const diffMinutes = Math.floor((diffMs / 1000 / 60) % 60);
            const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

            return `${this.padZero(diffHours)}小时${this.padZero(diffMinutes)}分${this.padZero(diffSeconds)}秒后开启`;
        },
        updateTimeDifferences() {
            this.timeDifferences = this.paperData_.map(row => this.calculateTimeStatus(row.startAt,row.endAt, row.state));
        },
        daojishi(targetDate){
            const now = new Date();
            const target = new Date(targetDate);
            const diffMs = target - now;
            if (diffMs < 5000) {
                clearInterval(this.daojishi_inti_key)
                this.push_change()
            }
            const diffSeconds = Math.floor((diffMs / 1000) % 60);
            const diffMinutes = Math.floor((diffMs / 1000 / 60) % 60);
            const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

            this.daojishi_text =  `${this.padZero(diffHours)}:${this.padZero(diffMinutes)}:${this.padZero(diffSeconds)}`;
        },
        daojishi_inti(targetDate){
            this.daojishi_inti_key = setInterval(()=>{
                this.daojishi(targetDate)
            },1000)
        },
    },
    mounted() {
        // 初始化时间差
        this.updateTimeDifferences();
        // 每秒更新一次时间差
        setInterval(() => {
            this.updateTimeDifferences();
        }, 1000);
    },
    components:{
        PaperAfter
    }
}
</script>

<style>
.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
    color: #818181;
}
</style>
<style scoped>
.cardBox {
    min-height: 300px;
    min-width: 800px;
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
}
.card {
    width: 270px;
    border: none;
    cursor: pointer;
}
.card:hover .courseName {
    color: #e72b47;
}
.image {
    object-fit: cover;
    height: 130px;
    width: 240px;
    border-radius: 5px;
}
.classMessageBox {
    padding: 0 5px;
    margin-bottom: -18px;
}
.courseName {
    transition: .3s;
    font-weight: 600;
    padding: 8px 0;
}
.teachers {
    font-size: 13px;
    color: #868686;
    padding: 2px 0;
}
.courseTask {
    font-size: 13px;
    color: #a1a1a1;
    padding: 2px 0;
}
.messageBox {
    margin: 25px 25px 25px 60px;
}
.messageBox h3 {
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 15px;
}
</style>
