<template>
    <div class="common-layout">
        <el-container>
            <el-header class="top-bar">
                <div>
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        placement="bottom"
                        raw-content
                    >
                        <template #content>
                            <div style="padding: 15px 5px;">
                                <p class="logoutBtn" @click="logout()">退出登录</p><br>
                                <p class="logoutBtn" @click="showMessage(1)">编辑信息</p>
                            </div>
                        </template>
                        <p>您好，{{ studentName }}<ArrowDown style="height: 15px; padding-left: 5px;"/></p>
                    </el-tooltip>
                </div>
            </el-header>
            <el-container class="body-div">
                <el-aside class="body-left" style="position: relative; overflow: visible;">
                    <div style="position: absolute; top: 0; right: 0; height: 48px; transform: translateY(-100%); display: flex; align-items: center;">
                        <img src="../assets/cuzLogo.png" style="height: 40px;">
                    </div>
                    <div class="leftBtnBox">
                        <div @click="showMessage(0)" class="classCenter">课程中心<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div>
                        <div @click="showMessage(4)" class="classCenter">考试中心<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div>
                        <div @click="showMessage(3)" class="classCenter">代码实践<img src="../assets/wind.svg"><img src="../assets/wind.svg"></div>
                    </div>
                    <!-- <div class="leftClassBox" :style="{ 'height': showClassList ? '320px':'80px'}">
                        <el-tree
                            ref="treeRef"
                            style="background-color: transparent;color: #e72b47;"
                            class="filter-tree"
                            :data="data"
                            :props="defaultProps"
                            default-expand-all
                            :filter-node-method="filterNode"
                            @node-click="choiseClass"
                            v-if="showClassList"
                        />
                        <p v-else>未选择课程</p>
                    </div> -->
                </el-aside>
                <el-main class="body-right">
                    <StudentClassList :style="{'display': isdisplay[0]}" @sendClickId="CatchClickId"/>
                    <StudentMessage :style="{'display': isdisplay[1]}" @click="showClassList=false"/>
                    <StudentVideo :style="{'display': isdisplay[2]}" :courseData="courseData" @showMessage="showMessage"/>
                    <StudentPython :style="{'display': isdisplay[3]}"/>
                    <StudentPaperList :style="{'display': isdisplay[4]}" :paperData="paperData"/>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>


<script>
import { logout, getCredentials, AXIOS_URL } from '../constants.js'
import StudentClassList from './StudentClassList.vue'
import StudentMessage from './StudentMessage.vue'
import StudentVideo from './StudentVideo.vue'
import StudentPython from './StudentPython.vue'
import StudentPaperList from './StudentPaperList.vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'


export default {
    data(){
        return{
            studentName: '同学',
            // 课程、个人信息
            isdisplay: ['none','none','none','none','black'],
            showClassList:false,
            data:[],
            paperData:[],
            videoData:0,

            currentClickId: null,
        }
    },
    mounted(){
        this.studentName = getCredentials().urSession.name
        this.showMessage(4)
    },
    methods:{
        logout(){
            logout();
        },
        showMessage(num){
            if(num===0)this.showClassList=false
            else if(num===4)this.getPaperList()
            this.isdisplay = this.isdisplay.map(() => {
                return 'none'; 
            });
            this.isdisplay[num] = 'block'
        },
        CatchClickId(id){
            axios({
                method: 'GET',
                url: AXIOS_URL+'/student/course/chapter/tree',
                params:{
                    id: id
                },
                headers: {
                    'Content-Type': 'application/json'
                }
                
            })
            .then((response)=>{
                this.courseData = response.data.data
                this.showMessage(2)
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '课程点信息获取失败',
                })
            });
        },
        choiseClass(e){
            if(e.tasked){
                axios({
                    method: 'GET',
                    url: AXIOS_URL+'/student/course/chapter/detail',
                    params:{
                        id: e.id
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                    
                })
                .then((response)=>{
                    this.videoData = response.data.data
                    this.showMessage(2)
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '课程点信息获取失败',
                    })
                });
            }
        },
        getPaperList(){
            ElMessage({
                message: '考试列表拉取中...',
                type: 'info',
            })
            axios.post(AXIOS_URL+'/student/paper/pageSearch',{page:1,size:10,userScore:null,state:null,paperAt:null,paperDifficulty:null,paperTitle:null})
                .then(response => {
                    // 处理成功响应
                    // console.log(response);
                    this.paperData = response.data.data.records
                    var pnum = 2
                    while(pnum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/student/paper/pageSearch',{page:pnum,size:10,userScore:null,state:null,paperAt:null,paperDifficulty:null,paperTitle:null})
                        .then(response => {
                            this.paperData = this.paperData.concat(response.data.data.records)
                        })
                        pnum+=1
                    }
                    ElMessage({
                        message: '考试列表拉取成功',
                        type: 'success',
                    })
                })
                .catch(() => {
                    ElMessage.error({
                        message: '考试列表拉取失败',
                    })
                });
        }
    },
    components:{
        StudentClassList,
        StudentMessage,
        StudentVideo,
        StudentPython,
        StudentPaperList
    }
}
</script>


<style>
.leftClassBox p{
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: .6;
    transform: translateX(-50%) translateY(-50%);
}
.leftClassBox{
    position: relative;
    /* background: linear-gradient(to bottom, transparent, #f2f4f7 20%, #f2f4f7 80%, transparent); */
    margin: 0 auto;
    padding: 15px 0;
    transition: .5s ease all;
    box-sizing: border-box;
    width: 80%;
    border-radius: 4px;
}
.leftBtnBox{
    margin: 40px auto 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}
.classCenter{
    margin: 15px 0;
    transition: .5s ease all;
    cursor: pointer;
    color: #e72b47;
    font-size: 14px;
    border: #e72b47 2px solid;
    display: inline-block;
    padding: 7px 10px;
    border-radius: 4px;
}
.classCenter:hover{
    background-image: radial-gradient(circle, #e72b47af, #e72b47dd);
    color: #f2f4f7;
}
.top-bar{
    background-color: #e72b47;
    height: 48px;
    z-index: 99;
}
.top-bar div{
    float: right;
    display: flex;
    align-items: center;
    height: 48px;
}
.top-bar p{
    cursor: pointer;
    font-family: '宋体';
    color: #ffffff;
    display: flex;
    align-items: center;
}
.logoutBtn{
    cursor: pointer;
    transition: .3s;
    font-size: 13px;
}
.logoutBtn:hover{
    color: #e72b47;
}
.body-div{
    padding: 0 8vw;
}
.body-left{
    width: 230px;
    min-height: 80vh;
    background-image: 
        linear-gradient(to bottom, rgb(240, 213, 146, .95), rgb(243, 227, 188, .75)), /* 渐变背景 */
        url('../assets/bgRepeat.jpg'); /* 图片背景 */
    background-size: 70%;
    z-index: 99;
}
.body-right{
    margin-left: 15px;
    z-index: 9;
    min-width: 920px;
    height: 100vh;
}
.body-right::-webkit-scrollbar {
    width: 8px;
}
.body-right::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #e4e4e4;
}
.body-right::-webkit-scrollbar-track {
    border-radius: 12px;
    background: #f2f4f700;
}
.classCenter img:nth-child(1){
    left: -5px;
    height: 22px;
    position: absolute;
    top: 60%;
    transition: .3s ease all;
    transform: rotateY(180deg) translateY(-50%);
}
.classCenter img:nth-child(2){
    right: -5px;
    height: 22px;
    position: absolute;
    top: 40%;
    transition: .3s ease all;
    transform: translateY(-50%) rotateX(180deg);
}
</style>