<template>
    <div class="cardBox">
        <div class="messageBox">
            <h3>用户管理</h3>
            <div @click="addUserVisible=true" class="buttomTop">新增用户</div>
            <div class="searchBox">
                <p>具体条件搜索</p>
                <el-input
                    v-model="search_username"
                    style="width: 180px"
                    placeholder="选填学号/工号"
                    clearable
                    size="small"
                />
                <el-input
                    v-model="search_name"
                    style="width: 140px"
                    placeholder="选填姓名"
                    clearable
                    size="small"
                />
                <el-select v-model="search_sex" placeholder="选填性别" style="width: 100px" size="small" clearable>
                    <el-option
                        v-for="item in box_sex"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id"
                    />
                </el-select>
                <el-select v-model="search_role" placeholder="选填权限" style="width: 100px" size="small" clearable>
                    <el-option
                        v-for="item in box_role"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id"
                    />
                </el-select>
                <div class="searchBoxdiv" @click="search">搜索</div>
            </div>
            <el-table :data="slicedData" style="width: 100%">
                <el-table-column label="Id" prop="id" show-overflow-tooltip=true width="80px"/>
                <el-table-column label="学号/工号" prop="username" show-overflow-tooltip=true width="200px"/>
                <el-table-column label="姓名" prop="name" show-overflow-tooltip=true width="150px"/>
                <el-table-column label="性别" show-overflow-tooltip=true width="80px">
                    <template #default="scope">
                        {{ scope.row.sex === 1?'男':scope.row.sex === 0?'女':'未知' }}
                    </template>
                </el-table-column>
                <el-table-column label="权限" show-overflow-tooltip=true width="150px">
                    <template #default="scope">
                        {{ scope.row.role === 0?'学生':scope.row.role === 1?'教师':'管理员' }}
                    </template>
                </el-table-column>
                <el-table-column align="right" width="140px">
                <template #header>
                    <el-input v-model="searchName" size="small" placeholder="快捷搜索用户姓名" @input="searchByName"/>
                </template>
                <template #default="scope">
                    <el-button size="small" @click="rePassword(scope)"
                    >重置密码</el-button
                    >
                    <el-button size="small" @click="reMessage(scope)"
                    >修改信息</el-button
                    >
                </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="totalWillShow" :default-page-size="10" v-model:current-page="currentPage"/>
        </div>

        <el-dialog v-model="dialogTableVisible" title="修改用户信息" width="800" :append-to-body=true>
            <span class="textLabel">学号/工号</span><el-input type="text" v-model="username" placeholder="添加用户名"/>
            <span class="textLabel">姓名</span><el-input type="text" v-model="name" placeholder="添加姓名"/>
            <span class="textLabel">性别</span><el-select placeholder="选择性别" v-model="sex">
                <el-option v-for="item in box_sex" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
                <div @click="cancel" class="buttomCancel">取消</div>
                <div @click="change" class="buttomSubmit">提交</div>
            </div>
        </el-dialog>

        <el-dialog v-model="addUserVisible" title="创建新用户" width="800" :append-to-body=true :destroy-on-close=true>
            <span class="textLabel">学号/工号</span><el-input type="text" v-model="add_username" placeholder="添加用户名"/>
            <span class="textLabel">姓名</span><el-input type="text" v-model="add_name" placeholder="添加姓名"/>
            <span class="textLabel">性别</span><el-select placeholder="选择性别" v-model="add_sex">
                <el-option v-for="item in box_sex" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
            <span class="textLabel">身份</span><el-select placeholder="选择身份" v-model="add_role">
                <el-option v-for="item in box_role" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="add_cancel" class="buttomCancel">取消</div>
            <div @click="add_change" class="buttomSubmit">新增</div></div>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { AXIOS_URL } from '../constants.js'
import axios from 'axios'

export default {
    props:['userData','userTotal'],
    data(){
        return{
            search_name:null,
            search_username:null,
            search_sex:null,
            search_role:null,
            searchName:'',
            dataWillShow:[],
            dataWillShow_org:[],
            dialogTableVisible: false,
            username:'',
            name:'',
            sex:'',
            role:'',
            id:0,
            totalWillShow:0,
            currentPage:1,
            addUserVisible: false,
            add_username:'',
            add_name:'',
            add_sex:'',
            add_role:'',

            box_sex:[{id:0,label:'女'},{id:1,label:'男'},{id:2,label:'未知'}],
            box_role:[{id:0,label:'学生'},{id:1,label:'教师'},{id:2,label:'管理员'}]
        }
    },
    computed:{
        slicedData() {
            const start = (this.currentPage - 1) * 10;
            const end = Math.min(this.currentPage * 10, this.dataWillShow.length);
            return this.dataWillShow.slice(start, end);
        }
    },
    mounted(){
    },
    watch: {
        userData: {
            immediate: true, // 立即执行
            handler(newVal) {
                newVal.sort((a, b) => {
                    return a.id - b.id; // 按 id 升序排序
                });
                this.dataWillShow = newVal;
                this.dataWillShow_org = newVal;
                this.currentPage = 1
            }
        },
        userTotal: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.totalWillShow = newVal;
            }
        },
    },
    methods:{
        search(){
            this.getPageMessage(1,this.search_username===''?null:this.search_username,this.search_name===''?null:this.search_name,this.search_sex===''?null:this.search_sex,this.search_role===''?null:this.search_role)
        },
        add_cancel(){
            this.addUserVisible = false
            this.add_username=''
            this.add_name=''
            this.add_sex=''
            this.add_role=''
        },
        add_change(){
            axios.post(AXIOS_URL+'/admin/user/add',{name:this.add_name,sex:this.add_sex,username:this.add_username,role:this.add_role})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '用户新增成功',
                        type: 'success',
                    })
                    this.getPageMessage(1,null,null,null,null)
                    this.addUserVisible = false
                    this.add_username=''
                    this.add_name=''
                    this.add_sex=''
                    this.add_role=''
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '用户新增失败',
                })
            });
        },
        // changePage(e){
        //     this.getPageMessage(e);
        // },
        cancel(){
            this.dialogTableVisible  =false
        },
        getPageMessage(num,username,name,sex,role){
            axios.post(AXIOS_URL+'/admin/user/pageSearch',{page:num,size:10,name,username,sex,role})
                .then(response => {
                    // 处理成功响应
                    this.totalWillShow = response.data.data.total
                    this.dataWillShow = response.data.data.records
                    this.dataWillShow_org = response.data.data.records
                    var unum = 2
                    while(unum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/user/pageSearch',{page:unum,size:10,name,username,sex,role})
                        .then(response => {
                            this.dataWillShow = response.data.data.records.concat(this.dataWillShow)
                            this.dataWillShow.sort((a, b) => {
                                return a.id - b.id; // 按 id 升序排序
                            });
                            this.dataWillShow_org = this.dataWillShow
                        })
                        unum+=1
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
        },
        change(){
            axios.put(AXIOS_URL+'/admin/user/update',{id:this.id,name:this.name,sex:this.sex,username:this.username},
                {headers:{
                    'Content-Type': 'application/json'
                }})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '信息修改成功',
                        type: 'success',
                    })
                    this.getPageMessage(1,null,null,null,null)
                    this.dialogTableVisible = false
                }else{
                    ElMessage({
                        message: response.data.msg,
                        type: 'success',
                    })
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '信息修改失败',
                })
            });
        },
        searchByName(){
            this.dataWillShow = this.dataWillShow_org.filter(item => item.name.includes(this.searchName))
        },
        rePassword(scope){
            // console.log(scope.row.id);
            axios.put(AXIOS_URL+'/admin/user/resetPassword',{id:scope.row.id})
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '密码重置成功',
                        type: 'success',
                    })
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '密码重置失败',
                })
            });
        },
        reMessage(scope){
            this.username = scope.row.username
            this.name = scope.row.name
            this.id = scope.row.id
            this.dialogTableVisible = true
            this.sex = scope.row.sex
        }
    }
}
</script>
<style>
:root{
    --el-color-primary:#e72b47;
}
.el-input-number__decrease:hover, .el-input-number__increase:hover {
    color: #e72b47;
}
.el-pagination{
    --el-pagination-hover-color: #e72b47;
}
.el-rate{
    --el-rate-fill-color: #e72b47;
}
.el-textarea{
    --el-input-focus-border-color: #e72b47;
}
.textLabel{
    display: block;
    font-size: 13px;
    opacity: .7;
    margin: 10px 0 1px 5px;
}
.buttomSubmit,.buttomCancel{
    border: #e72b47 solid 2px;
    transition: .3s ease-in;
    background-color: #e72b47;
    color: #ffffff;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 5px;
}
.buttomSubmit:hover,.buttomCancel:hover{
    border-color: #921d2f;
    background-color: #921d2f;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.is-selected::after{
    background-color: #e72b47;
}
.el-select-dropdown__item.is-selected{
    font-weight: 400;
    color: #e72b47;
}
.el-select__wrapper.is-focused{
    box-shadow: 0 0 0 1px #e72b47 inset;
}
.el-button:focus, .el-button:hover {
    color: var(--el-button-hover-text-color);
    border-color: #ffc6c6;
    background-color: #ffecec;
    outline: 0;
}
.el-button{
    --el-button-hover-text-color: #e72b47;
}
.el-input__wrapper.is-focus{
    box-shadow: 0 0 0 1px #e72b47 inset;
}
.el-table{
    margin-bottom: 15px;
}
.el-pagination.is-background .el-pager li.is-active{
    background-color: #e72b47;
}
.buttomTop{
    position: absolute;
    right: 0;top: 0;
    padding: 5px 8px;
    background-color: #e72b47;
    border: #e72b47 solid 2px;
    transition: .3s ease-in;
    color: #ffffff;
    cursor: pointer;
    border-radius: 4px;
}
.buttomTop:hover{
    background-color: #ffecec;
    color: #e72b47;
}
.messageBox{
    position: relative;
}
</style>
<style scoped>
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px 15px 50px 15px;
    height: auto;
}
.messageBox{
    margin: 25px 60px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 15px;
}
.searchBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 5px;
    box-sizing: border-box;
    overflow: hidden;
    border: #e72b47 1px solid;
    border-radius: 20px;
    background-color: #ffecec;
}
.searchBox p{
    font-size: 13px;
    color: #e72b47;
    font-weight: 600;
    padding-left: 20px;
}
.searchBoxdiv{
    background-color: #e72b47;
    height: 35px;
    width: 55px;
    text-align: center;
    line-height: 35px;
    font-size: 13px;
    color: #ffecec;
    cursor: pointer;
}
</style>