<template>
    <div class="cardBox">
        <div class="messageBox">
        <h3>
            <span style="cursor: pointer;" @click="handleClick">
                <svg fill="none" stroke="currentColor" stroke-width="4" viewBox="0 0 48 48" aria-hidden="true" focusable="false" stroke-linecap="butt" stroke-linejoin="miter" class="arco-icon arco-icon-left" style="font-size: 18px;"><path d="M32 8.4 16.444 23.956 32 39.513"></path></svg>
            </span> 
            课程中心
        </h3>
        <div class="courseBox">
            <div class="left">
                <el-tree
                    style="max-width: 600px;user-select: none;"
                    :data="courseData"
                    default-expand-all
                    :height="208"
                    @node-click="showVideo"
                />
            </div>
            <div class="right">
                <p v-if="!videoData" class="righttext">请先选择章节</p>
                <div v-else class="rightVideo">
                    <!-- <img :src="videoData.coverUrl"> -->
                    <video-player
                        class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="{ ...playerOptions, sources: [{ src: videoData?.playUrl, type: 'video/mp4' }] }"
                        controls
                        @timeupdate="onPlayerTimeupdate"
                    />
                    <!-- <video 
                        :src="videoData.playUrl" 
                        controls 
                        @play="handlePlay"
                        @timeupdate="handleTimeUpdate"
                    ></video> -->
                    <div class="rightQuestion" v-if="showQueastion">
                        <EditorMathText :editorText=videoData.question.title :isPointer="false"/>
                        <el-radio-group v-model="questionAnswer" v-if="videoData.question.type===0">
                            <el-radio
                                v-for="(value, key) in JSON.parse(videoData.question.option)"
                                :key="key"
                                :label="key"
                                :value="value"
                                style="display: inline-block; width: 100%;line-height: 35px;color: white;"
                            >
                                {{ key }}，{{ value }}
                            </el-radio>
                        </el-radio-group>
                        <el-checkbox-group v-model="questionAnswer" v-if="videoData.question.type===1">
                            <el-checkbox
                                v-for="(value, key) in JSON.parse(videoData.question.option)"
                                :key="key"
                                :label="key"
                                :value="value"
                                style="display: inline-block; width: 100%; line-height: 35px;color: white;"
                            >
                                {{ key }}，{{ value }}
                            </el-checkbox>
                        </el-checkbox-group>
                        <div class="submit" @click="submit">提交</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { AXIOS_URL } from '../constants.js'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import EditorMathText from './EditorMathText.vue'
import "katex/dist/katex.min.css";

// import { getCredentials } from '../constants.js'

export default {
    components: {
        EditorMathText,
    },
    props:{
        courseData:{
            type: Object,
        },
        triggerParentMethod: {
            type: Function,
            required: true
        }
    },
    data(){
        return{
            videoData: null,
            playerOptions: {
                notSupportedMessage: '此视频暂无法播放，请稍后再试',
                // playbackRates: [0.7, 1.0, 1.5, 2.0],
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    fullscreenToggle: true,  // 全屏按钮
                    remainingTimeDisplay: true,
                }
            },
            questionShowTime: 0,
            isFinishQuestion: false,
            showQueastion: false,
            questionAnswer: '',
            player: null,
        }
    },
    mounted(){
        
    },
    methods:{
        submit(){
            if(this.questionAnswer===this.videoData.question.answer){
                this.isFinishQuestion = true
                this.showQueastion = false
                this.player.play();
            }else{
                ElMessage.error({
                    message: '题目答案错误',
                })
            }
            
        },
        onPlayerTimeupdate() {
            this.player = this.$refs.videoPlayer.$el.querySelector('video'); // 获取 video.js 播放器实例
            const currentTime = Math.floor(this.player.currentTime); // 获取当前播放时间
            if(currentTime>=this.questionShowTime&&!this.isFinishQuestion){
                // 暂停视频播放
                this.player.pause(); 
                this.showQueastion = true
            }
        },
        handleClick() {
            this.$emit('showMessage', 0);
        },
        showVideo(node){
            if (node.children && node.children.length === 0) {
                axios({
                    method: 'GET',
                    url: AXIOS_URL+'/student/course/chapter/detail',
                    params:{
                        id: node.id
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response)=>{
                    this.videoData = response.data.data;
                    this.questionShowTime = this.getRandomIntInRange(response.data.data.duration)
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '章节信息获取失败',
                    })
                });
            }
        },
        getRandomIntInRange(value) {
            // 获取最高位的值
            const highestDigit = Math.pow(10, Math.floor(Math.log10(value)));

            // 计算下界和上界
            const lowerBound = highestDigit;
            const upperBound = value - 1;

            // 生成随机整数
            if (lowerBound > upperBound) {
                throw new Error("Lower bound exceeds upper bound.");
            }
            
            return Math.floor(Math.random() * (upperBound - lowerBound + 1)) + lowerBound;
        },
        handlePlay() {
            console.log('start');
        },
        handleTimeUpdate(event) {
            this.currentTime = event.target.currentTime;
            console.log('Current Time:', this.currentTime, 'seconds');
        }
    }
}
</script>

<style>
/* 禁用拖动功能，保留显示进度条 */
.vjs-progress-control {
  pointer-events: none; /* 允许点击事件，但不允许拖动 */
}
.ProseMirror{
    border: none;
}
</style>
<style scoped>
.submit{
    padding: 8px 16px;
    background-color: #db3838;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;
    position: absolute;
    bottom: 40px;
    right: 40px;
}
.rightQuestion{
    height: 100%;
    width: 100%;
    background-color: #000000b2;
    color: #ffffff;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    text-align: left;
    padding: 40px;
}
.rightVideo video{
    width: 600px;
}
.rightVideo{
    height: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    margin: 50px auto;
    background-color: #000000;
}
.righttext{
    font-size: 14px;
    opacity: .6;
}
.right{
    text-align: center;
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left{
    width: 25%;
    min-height: 400px;
    overflow: hidden;
    border-right: #e6e6e6 1px solid;
}
.courseBox{
    display: flex;
}
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
    height: 101vh;
}
.card{
    width: 270px;
    border: none;
    cursor: pointer;
}
.card:hover .courseName{
    color: #e72b47;
}
.image{
    object-fit: cover;
    height: 130px;
    width: 240px;
    border-radius: 5px;
}
.classMessageBox{
    padding: 0 5px;
    margin-bottom: -18px;
}
.courseName{
    transition: .3s;
    font-weight: 600;
    padding: 8px 0;
}
.teachers{
    font-size: 13px;
    color: #868686;
    padding: 2px 0;
}
.courseTask{
    font-size: 13px;
    color: #a1a1a1;
    padding: 2px 0;
}
.messageBox{
    margin: 25px 0 25px 60px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}
</style>
