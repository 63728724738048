<template>
    <div class="cardBox">
        <div class="messageBox">
            <h3>课程管理</h3>
            <div @click="show_add()" class="buttomTop">新增课程</div>
            <el-table :data="slicedData" style="width: 100%">
                <el-table-column label="封面" prop="coverUrl" show-overflow-tooltip=true width="160px" style="overflow: hidden;">
                    <!-- <template #default="scope">
                        <img :src="scope.row.coverUrl" style="height: 80px;">
                    </template> -->
                    <template #default="scope">
                        <div style="height: 80px;overflow: hidden;">
                            <img :src="scope.row.coverUrl" style="width: 130px;">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Id" prop="id" show-overflow-tooltip=true width="60px"/>
                <el-table-column label="课程名" prop="name" show-overflow-tooltip=true width="180px"/>
                <el-table-column label="任课教师" prop="teacherVos" show-overflow-tooltip=true width="160px">
                    <template #default="scope">
                        <div style="white-space: pre-wrap;">
                            <el-tag v-for="teacher in scope.row.teacherVos" type="danger" :key="teacher.id">{{ teacher.name }}</el-tag>
                            <el-tag v-if="scope.row.teacherVos.length===0" type="danger">暂无</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="班级数" prop="classNumber" show-overflow-tooltip=true width="70px"/>
                <el-table-column align="right" width="200px">
                <template #header>
                    <el-input v-model="searchName" size="small" placeholder="搜索课程名" @input="searchByName"/>
                </template>
                <template #default="scope">
                    <el-button size="small" @click="reMessage(scope)">课程信息</el-button>
                    <el-button size="small" @click="reTeacher(scope)">授课教师</el-button>
                    <el-button size="small" @click="reClass(scope)">受课班级</el-button>
                    <el-button size="small" @click="reFile(scope)">课程资料</el-button>
                    <el-button size="small" @click="reZJ(scope)">课程章节</el-button>
                </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="totalWillShow" :default-page-size="10" v-model:current-page="currentPage"/>
        </div>

        <el-dialog v-model="ZJVisible" title="查看课程章节树" width="1000" :append-to-body=true @close="ZJ_cancel">
            <AdminCourseZJ :ZJdata="ZJdata" :searchZjdata="searchZjdata" :jscourseid="jscourseid"></AdminCourseZJ>
            
            <el-dialog v-model="ZJVisible_add" title="添加子章节" width="800" :append-to-body=true @close="ZJadd_cancel">
                <span class="textLabel">章节名称</span><el-input type="text" v-model="label" placeholder="添加章节名称"/>
                <div style="display: flex;justify-content: right; margin-top: 15px;">
                <div @click="cancel" class="buttomCancel">取消</div>
                <div @click="add" class="buttomSubmit">添加</div></div>
            </el-dialog>

            <el-dialog v-model="ZJVisible_change" title="编辑章节" width="800" :append-to-body=true @close="ZJchange_cancel">
                <span class="textLabel">章节名称</span><el-input type="text" v-model="change_label" placeholder="添加章节名称"/>
                <div style="display: flex;justify-content: right; margin-top: 15px;">
                <div @click="cancel_change" class="buttomCancel">取消</div>
                <div @click="change" class="buttomSubmit">修改</div></div>
            </el-dialog>
        </el-dialog>
        
        <el-dialog v-model="dialogTableVisible" title="修改课程基础信息" width="800" :append-to-body=true @close="cancel">
            <span class="textLabel" v-if="or_addFile_changeClass==='change'">课程名</span><el-input type="text" placeholder="课程名" v-model="change_className" v-if="or_addFile_changeClass==='change'"/>
            <span class="textLabel" v-if="or_addFile_changeClass==='add'">文件名称</span><el-input type="text" placeholder="文件名称" v-model="addFile_filename" v-if="or_addFile_changeClass==='add'"/>
            <span class="textLabel">{{ or_addFile_changeClass==='change'?'课程封面':'课程资料' }}</span>
            <!-- <el-input type="text" placeholder="课程封面路径" v-model="change_classPicUrl"/> -->
            <el-upload
                class="upload-demo"
                drag
                @change="(e)=>uploadFile(e,'change')"
                :auto-upload=false
                ref="myUpload"
                :limit="1"
                :on-exceed="handleExceed"
                @remove="removeFile"
            >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                拖拽 或 <em>点击上传文件</em>
                </div>
            </el-upload>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="cancel" class="buttomCancel">取消</div>
            <div @click="change" class="buttomSubmit">{{ or_addFile_changeClass==='change'?'修改':'上传' }}</div></div>
        </el-dialog>

        <el-dialog v-model="addUserVisible" title="创建新课程" width="800" :append-to-body=true :destroy-on-close=true @close="add_cancel">
            <span class="textLabel">课程名</span><el-input type="text" placeholder="添加课程名" v-model="className"/>
            <span class="textLabel">课程封面</span>
            <!-- <el-input type="text" placeholder="课程封面路径" v-model="change_classPicUrl"/> -->
            <el-upload
                class="upload-demo"
                drag
                @change="(e)=>uploadFile(e,'add')"
                :auto-upload=false
                ref="myUpload"
                :limit="1"
                :on-exceed="handleExceed"
                @remove="removeFile"
                disabled
            >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                拖拽 或 <em>点击上传文件</em>
                </div>
            </el-upload>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="add_cancel" class="buttomCancel">取消</div>
            <div @click="add_change" class="buttomSubmit">创建</div></div>
        </el-dialog>

        <el-dialog v-model="changeTeacherVisible" title="编辑授课教师" width="800" :append-to-body=true :destroy-on-close=true @close="teacher_cancel">
            <span class="textLabel">已有教师</span><el-tag v-for="teacher in dataWillShow_userInCourse" type="danger" :key="teacher.id" :closable="teacher.id===0?false:true" @close="deleteTeacher(teacher.id,teacher.name)">{{ teacher.name }}</el-tag>
            <el-tag v-if="change_teacher.length===0" type="danger">暂无</el-tag>
            <span class="textLabel">添加教师</span>
            <el-table :data="dataWillShow_user" style="width: 100%">
                <el-table-column label="Id" prop="id" show-overflow-tooltip=true width="80px"/>
                <el-table-column label="工号" prop="username" show-overflow-tooltip=true width="200px"/>
                <el-table-column label="姓名" prop="name" show-overflow-tooltip=true width="150px"/>
                <el-table-column label="性别" show-overflow-tooltip=true width="80px">
                    <template #default="scope">
                        {{ scope.row.sex = 1?'男':'女' }}
                    </template>
                </el-table-column>
                <el-table-column label="权限" show-overflow-tooltip=true width="100px">
                    <template #default="scope">
                        {{ scope.row.role === 0?'学生':scope.row.role === 1?'教师':'管理员' }}
                    </template>
                </el-table-column>
                <el-table-column align="right" width="140px">
                <template #header>
                    <el-input v-model="searchName" size="small" placeholder="快捷搜索教师姓名" @input="searchByName"/>
                </template>
                <template #default="scope">
                    <el-button size="small" @click="teacher_change(scope.row.id)"
                    >设置为授课教师</el-button
                    >
                </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="totalWillShow_teacher" :default-page-size="10" v-model:current-page="currentPage_teacher"/>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="teacher_cancel" class="buttomCancel">关闭</div></div>
        </el-dialog>

        <el-dialog v-model="changeClassVisible" title="编辑受课班级" width="800" :append-to-body=true :destroy-on-close=true>
            <span class="textLabel">已有班级</span>
            <el-tag v-for="classes in change_classes" type="danger" :key="classes.id" closable @close="deleteClass(classes.id,classes.name)">{{ classes.name }}</el-tag>
            <el-tag v-if="change_classes.length===0" type="danger">暂无</el-tag>
            <span class="textLabel">添加班级</span>
            <el-table :data="box_classes_org" style="width: 100%">
                <el-table-column label="Id" prop="id" show-overflow-tooltip=true width="70px"/>
                <el-table-column label="班级名" prop="name"  show-overflow-tooltip=true width="230px"/>
                <el-table-column label="人数" prop="studentNumber" show-overflow-tooltip=true width="150px"/>
                <el-table-column align="right" width="300px">
                <template #header>
                    <el-input v-model="searchName" size="small" placeholder="搜索班级名称" @input="searchByName"/>
                </template>
                <template #default="scope">
                    <el-button size="small" @click="addClassToCourse(scope)"
                    >加入受课班级</el-button
                    >
                </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="totalWillShow_class" :default-page-size="10" v-model:current-page="currentPage_class"/>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
            <div @click="classes_cancel" class="buttomCancel">关闭</div></div>
        </el-dialog>

        <el-dialog v-model="fileVisible" title="课程资料" width="1200" :append-to-body=true :destroy-on-close=true>
            <el-table :data="dataWillShow_courseFileList" style="width: 100%" >
                <span class="textLabel">资料id</span><el-table-column label="资料id" prop="id" width="70"/>
                <span class="textLabel">资料路径</span><el-table-column label="资料路径" prop="fileUrl" width="420"/>
                <span class="textLabel">资料名</span><el-table-column label="资料名" prop="filename" width="300"/>
                <span class="textLabel">创建时间</span><el-table-column label="创建时间" prop="createAt" width="250"/>
                <el-table-column align="right">
                <template #header>
                    <el-button @click="()=>dialogTableVisible=true">上传资料</el-button>
                </template>
                <template #default="scope">
                    <el-button size="small" @click="()=>moveFile(scope)"
                    >删除资料</el-button
                    >
                </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { AXIOS_URL } from '../constants.js'
import OSS from 'ali-oss';
import {nanoid} from 'nanoid'
import axios from 'axios'
import AdminCourseZJ from './AdminCourseZJ.vue';
// import {Buffer} from 'buffer';

export default {
  components: { AdminCourseZJ },
    props:['courseData','courseTotal'],
    data(){
        return{
            searchName:'',
            dataWillShow:[],
            dataWillShow_org:[],
            dialogTableVisible: false,
            changeClassVisible:false,
            changeTeacherVisible:false,
            totalWillShow:0,
            currentPage:1,
            addUserVisible: false,
            teacharInputVisible:false,
            fileVisible:false,
            inputValue:'',
            optionName:'',
            optionName_classes:'',

            change_id:-1,
            change_teachers:[],
            change_classes:[],
            change_className:'',
            change_classPicUrl:'',

            className:'',
            classPicUrl:'',

            box_teacher_org:[],
            box_classes_org:[],

            dataWillShow_org_file:[],
            dataWillShow_file:[],
            totalWillShow_file:0,

            file_coursePic_file:'',
            file_coursePic_name:'',
            SecretId:'',
            SecretKey:'',
            SecurityToken:'',

            dataWillShow_user:[],
            
            change_teacher:[],
            dataWillShow_userInCourse:[],

            currentPage_class:1,
            totalWillShow_class:0,
            currentPage_teacher:1,
            totalWillShow_teacher:0,

            dataWillShow_courseFileList:[],

            or_addFile_changeClass: '',
            addFile_classname:'',
            addFile_classid:'',
            addFile_filepath:'',
            addFile_filename:'',

            ZJVisible: false,
            ZJdata: [],
            ZJVisible_add: false,
            ZJVisible_change: false,
            jscourseid: -1,
            change_label: '',
        }
    },
    computed:{
        slicedData() {
            const start = (this.currentPage - 1) * 10;
            const end = Math.min(this.currentPage * 10, this.dataWillShow.length);
            return this.dataWillShow.slice(start, end);
        },
        box_teacher() {
            return this.box_teacher_org.filter(item => item.name.includes(this.optionName))
        },
        box_classes() {
            return this.box_classes_org.filter(item => item.name.includes(this.optionName_classes))
        }
    },
    mounted(){
    },
    watch: {
        courseData: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.dataWillShow = newVal;
                this.dataWillShow_org = newVal;
                this.currentPage = 1
            }
        },
        courseTotal: {
            immediate: true, // 立即执行
            handler(newVal) {
                this.totalWillShow = newVal;
            }
        },
    },
    methods:{
        ZJappend(data){
            console.log(data);
            this.ZJVisible_add = true
        },
        ZJchange(data){
            this.change_label = data.label
            this.ZJVisible_change = true
            // axios.put(AXIOS_URL+'/admin/course/chapter/update',{
                
            //     }, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // })
            // .then(response => {
            //     console.log(response);
            //     if(response.data.code===1){
            //         ElMessage({
            //             message: '试卷更改成功',
            //             type: 'success',
            //         })
            //         this.topicCheck = []
            //         this.add_paperName = ''
            //         this.paperStartTime = ''
            //         this.paperEndTime = ''
            //         this.addPaperVisible = false
            //         this.re()
            //     }
            // })
            // .catch(() => {
            //     // 处理错误
            //     ElMessage.error({
            //         message: '试卷更改失败',
            //     })
            // });
        },
        ZJdelete(data){
            axios.delete(AXIOS_URL+'/admin/course/chapter/update',{
                headers: {
                    'Content-Type': 'application/json' // 可选：设置请求的内容类型
                },
                data: {
                    id: data.id
                }
            })
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '课程章节删除成功',
                        type: 'success',
                    })
                    this.searchZjdata(this.jscourseid)
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '课程章节删除失败',
                })
            });
        },
        ZJadd_cancel(){
            this.ZJVisible_add = false
        },
        ZJchange_cancel(){
            this.ZJVisible_change = false
        },
        searchZjdata(id){
            this.jscourseid = id
            axios({
                method: 'GET',
                url: AXIOS_URL+'/admin/course/chapter/tree',
                params:{
                    id: id
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    this.ZJdata = response.data.data
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: `课程章节树获取失败`,
                })
            });
        },
        reZJ(scope){
            console.log(scope);
            this.ZJVisible = true
            this.searchZjdata(scope.row.id)
        },
        ZJ_cancel(){
            this.ZJVisible = false
        },
        addClassToCourse(scope){
            axios.post(AXIOS_URL+'/admin/course/user/batchBindClass',
                {
                    id:this.change_id,
                    ids:[scope.row.id]
                }
            )
            .then(response => {
                if(response.data.code===1){
                    ElMessage({
                        message: '受课班级新增成功',
                        type: 'success',
                    })
                    this.getPageMessage(1)
                    this.getClassInCourse(scope.row.id)
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '受课班级新增失败',
                })
            });
        },
        getUserInCourse(num,name,username,sex,role,id){
            axios.post(AXIOS_URL+'/admin/course/user/pageSearch',{id,page:num,size:10,name,username,sex,role,classId:null})
                .then(response => {
                    // 处理成功响应
                    this.dataWillShow_userInCourse = response.data.data.records
                    var unum = 2
                    while(unum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/course/user/pageSearch',{page:unum,size:10,name,username,sex,role,classId:null})
                        .then(response => {
                            this.dataWillShow_userInCourse = response.data.data.records.concat(this.dataWillShow_userInCourse)
                            this.dataWillShow_userInCourse.sort((a, b) => {
                                return a.id - b.id; // 按 id 升序排序
                            });
                        })
                        unum+=1
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
        },
        handleExceed(files){
            let point = files[0]
            this.$refs['myUpload'].clearFiles()
            this.$refs['myUpload'].handleStart(point)
        },
        removeFile(){
                this.file_coursePic_file = ''
                this.file_coursePic_name = ''
        },
        uploadFile(e,kind){
            if(kind==='change'){
                if(!e.raw.type.includes('image')||e.raw.type.includes('gif')){
                    ElMessage({
                        message: '请上传非GIF的图片文件',
                        type: 'warning',
                    })
                    this.$refs['myUpload'].clearFiles();
                    this.file_coursePic_file = ''
                    this.file_coursePic_name = ''
                }else{
                    this.file_coursePic_name = nanoid()+'.'+e.name.split('.').pop();
                    // const blob = new Blob([e.raw], { type: e.raw.type });
                    // this.file_coursePic_file = e.raw;
                    // const text = JSON.stringify(e.raw); // 转换为字符串
                    // const encoder = new TextEncoder();
                    // const uint8Array = encoder.encode(text);
                    
                    // 将 Uint8Array 转换为 Blob
                    // const blob = new Blob([uint8Array], { type: 'application/json' });
                    this.file_coursePic_file = e.raw;
                }
            }else{
                this.file_coursePic_name = nanoid()+'.'+e.name.split('.').pop();
                this.file_coursePic_file = e.raw;
            }
        },
        reFile(scope){
            this.or_addFile_changeClass = 'add'
            this.fileVisible = true
            this.addFile_classname = scope.row.name
            this.addFile_classid = scope.row.id
            this.searchPage_coursefile(scope.row.id)
        },
        searchPage_coursefile(id){
            axios.post(AXIOS_URL+'/admin/course/file/pageSearch',{id:id,page:1,size:10})
                .then(response => {
                    // 处理成功响应
                    this.dataWillShow_courseFileList = response.data.data.records
                    var unum = 2
                    while(unum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/course/file/pageSearch',{id:id,page:unum,size:10})
                        .then(response => {
                            this.dataWillShow_courseFileList = response.data.data.records.concat(this.dataWillShow_courseFileList)
                            this.dataWillShow_courseFileList.sort((a, b) => {
                                return a.id - b.id; // 按 id 升序排序
                            });
                        })
                        unum+=1
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
        },
        deleteTeacher(id,name){
            ElMessageBox.confirm(
                `是否确认在课程授课教师中删除 ${name}？`,
                '确认',
                {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'info',
                }
            )
            .then(() => {
                axios.delete(AXIOS_URL+'/admin/course/user/unbind',{
                    headers: {
                        'Content-Type': 'application/json' // 可选：设置请求的内容类型
                    },
                    data: {
                        id: id
                    }
                })
                .then(response => {
                    // 处理成功响应
                    if(response.data.code===1){
                        ElMessage({
                            message: '课程授课教师更改成功',
                            type: 'success',
                        })
                        this.getUserInCourse(1,null,null,null,1,this.change_id)
                        this.getPageMessage(1)
                        this.optionName = ''
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '课程授课教师更改失败',
                    })
                });
            })
            .catch(() => {})
        },
        teacher_change(ids){
            axios.post(AXIOS_URL+'/admin/course/user/batchBind',{
                ids:[ids],
                id:this.change_id,
            })
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '课程授课教师更改成功',
                        type: 'success',
                    })
                    this.getPageMessage(this.currentPage)
                    this.getUserInCourse(1,null,null,null,1,this.change_id)
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '课程授课教师更改失败',
                })
            });
        },
        getClassInCourse(id){
            axios.post(AXIOS_URL+'/admin/course/user/pageSearchCourseClass',{id,page:1,size:10})
            .then(response => {
                // 处理成功响应
                this.change_classes = response.data.data.records
                var bnum = 2
                while(bnum<=response.data.data.pages){
                    axios.post(AXIOS_URL+'/admin/course/user/pageSearchCourseClass',{id,page:bnum,size:10})
                    .then(response => {
                        this.change_classes = response.data.data.records.concat(this.change_classes)
                    })
                    bnum+=1
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '课程数据获取数据失败',
                })
            });
        },
        deleteClass(id,name){
            ElMessageBox.confirm(
                `是否确认在课程受课班级中删除 ${name}？`,
                '确认',
                {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'info',
                }
            )
            .then(() => {
                // console.log('classId:',id);
                // console.log('courseId:',this.change_id);
                axios.delete(AXIOS_URL+'/admin/course/user/batchUnbindClass',{
                    headers: {
                        'Content-Type': 'application/json' // 可选：设置请求的内容类型
                    },
                    data: {
                        id:this.change_id,
                        ids:[id],
                    }
                })
                .then(response => {
                    // 处理成功响应
                    if(response.data.code===1){
                        ElMessage({
                            message: '课程受课班级删除成功',
                            type: 'success',
                        })
                        this.getClassInCourse(this.change_id)
                        this.getPageMessage(1)
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '课程受课班级删除失败',
                    })
                });
            })
            .catch(() => {})
        },
        classes_change(){
            axios.post(AXIOS_URL+'/admin/course/bindCourseClass',{
                classIds:this.change_classes_choose,
                courseId:this.change_id,
            })
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '受课班级更改成功',
                        type: 'success',
                    })
                    this.change_classes_choose=[]
                    this.getPageMessage(this.currentPage)
                    this.changeClassVisible=false
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '受课班级更改失败',
                })
            });
        },
        teacher_cancel(){
            this.optionName = ''
            this.change_teachers=[]
            this.changeTeacherVisible=false
        },
        classes_cancel(){
            this.optionName_classes = ''
            this.change_classes_choose=[]
            this.change_classes=[]
            this.changeClassVisible=false
        },
        handleInputConfirm(){
            // console.log(this.inputValue);
        },
        showInput(){
            this.teacharInputVisible = true
        },
        getClassList(num){
            axios.post(AXIOS_URL+'/admin/class/pageSearch',{page:num,size:10,name:null})
                .then(response => {
                    // 处理成功响应
                    this.totalWillShow_class = response.data.data.total
                    this.box_classes_org = response.data.data.records
                    var cnum = 2
                    while(cnum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/class/pageSearch',{page:cnum,size:10,name:null})
                        .then(response => {
                            this.box_classes_org = response.data.data.records.concat(this.box_classes_org)
                        })
                        cnum+=1
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
        },
        reClass(scope){
            // this.change_classes = response.data.data
            this.getClassInCourse(scope.row.id)
            this.change_id = scope.row.id
            // this.box_classes_org = response.data.data
            this.changeClassVisible=true
            this.getClassList(1)
        },
        reTeacher(scope){
            this.change_id = scope.row.id
            this.change_teacher = scope.row.teacherVos
            this.getUserInCourse(1,null,null,null,1,scope.row.id)
            this.changeTeacherVisible = true
            this.getPageMessage_user(1,null,null,null,1)
        },
        getPageMessage_user(num,name,username,sex,role){
            axios.post(AXIOS_URL+'/admin/user/pageSearch',{page:num,size:10,name,username,sex,role})
                .then(response => {
                    // 处理成功响应
                    this.totalWillShow_teacher = response.data.data.total
                    this.dataWillShow_user = response.data.data.records
                    var unum = 2
                    while(unum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/user/pageSearch',{page:unum,size:10,name,username,sex,role})
                        .then(response => {
                            this.dataWillShow_user = response.data.data.records.concat(this.dataWillShow_user)
                            this.dataWillShow_user.sort((a, b) => {
                                return a.id - b.id; // 按 id 升序排序
                            });
                        })
                        unum+=1
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '用户获取数据失败',
                    })
                });
        },
        show_add(){
            this.addUserVisible=true
        },
        add_cancel(){
            this.className = ''
            this.classPicUrl = ''
            this.addUserVisible = false
        },
        add_change(){
            axios.post(AXIOS_URL+'/admin/course/add',{name:this.className,coverPath:this.classPicUrl})
            .then(response => {
                // 处理成功响应
                // console.log(response);
                if(response.data.code===1){
                    ElMessage({
                        message: '课程新增成功',
                        type: 'success',
                    })
                    this.getPageMessage(1)
                    this.addUserVisible = false
                }else{
                    ElMessage.error({
                        message: response.data.msg,
                    })
                }
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '课程新增失败',
                })
            });
        },
        // changePage(e){
        //     this.getPageMessage(e);
        // },
        cancel(){
            this.dialogTableVisible  =false
            this.change_className = ''
            this.change_classPicUrl = ''
            this.$refs['myUpload'].clearFiles();
            this.file_coursePic_file = ''
            this.file_coursePic_name = ''
        },
        getPageMessage(num){
            axios.post(AXIOS_URL+'/admin/course/pageSearch',{page:num,size:10,name:null})
                .then(response => {
                    // 处理成功响应
                    this.totalWillShow = response.data.data.total
                    this.dataWillShow = response.data.data.records
                    this.dataWillShow_org = response.data.data.records
                    var conum = 2
                    while(conum<=response.data.data.pages){
                        axios.post(AXIOS_URL+'/admin/course/pageSearch',{page:conum,size:10,name:null})
                        .then(response => {
                            this.dataWillShow = response.data.data.records.concat(this.dataWillShow)
                            this.dataWillShow_org = response.data.data.records.concat(this.dataWillShow_org)
                        })
                        conum+=1
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
        },
        getPageMessage_file(id){
            axios({
                    method: 'GET',
                    url: AXIOS_URL+'/admin/course/pageCourseFile',
                    params:{
                        courseId: id,
                        page: 1,
                        size: '10'
                    }
                })
                .then(response => {
                    // 处理成功响应
                    this.totalWillShow_file = response.data.data.total
                    this.dataWillShow_file = response.data.data.records
                    this.dataWillShow_org_file = response.data.data.records
                    var conum_file = 2
                    while(conum_file<=response.data.data.pages){
                        axios({
                            method: 'GET',
                            url: AXIOS_URL+'/admin/course/pageCourseFile',
                            params:{
                                page: conum_file,
                                size: '10'
                            }
                        })
                        .then(response => {
                            this.dataWillShow_file = response.data.data.records.concat(this.dataWillShow)
                            this.dataWillShow_org_file = response.data.data.records.concat(this.dataWillShow_org)
                        })
                        conum_file+=1
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '获取数据失败',
                    })
                });
        },
        change(){
            if(this.or_addFile_changeClass==='change'){
                if(this.file_coursePic_file===''){
                    axios.put(AXIOS_URL+'/admin/course/update',{id:this.change_id,name:this.change_className,coverPath:this.change_classPicUrl})
                            .then(response => {
                                // 处理成功响应
                                if(response.data.code===1){
                                    ElMessage({
                                        message: '信息修改成功',
                                        type: 'success',
                                    })
                                    this.getPageMessage(this.currentPage)
                                    this.dialogTableVisible = false
                                }
                            })
                            .catch(() => {
                                // 处理错误
                                ElMessage.error({
                                    message: '信息修改失败',
                                })
                            });
                }else{
                    axios.get(AXIOS_URL+'/common/oss/getTempToken')
                    .then(response => {
                        const client = new OSS({
                            region: 'oss-cn-hangzhou',
                            accessKeyId: response.data.data.accessKeyId,
                            accessKeySecret: response.data.data.accessKeySecret,
                            stsToken: response.data.data.securityToken,
                            bucket: 'clsp',
                            secure: true,
                        })
                        try {
                            const options = {
                                meta: { temp: "demo" },
                                mime: "json",
                                headers: { "Content-Type": "application/json" },
                            };
                            // 上传文件
                            const loadingMessage = ElMessage({
                                message: '上传中...',
                                type: 'info',
                            });
                            client.put(`course/${this.change_className}/${this.file_coursePic_name}`, this.file_coursePic_file, options)
                            .then(() => {
                                loadingMessage.close();
                                axios.put(AXIOS_URL+'/admin/course/update',{id:this.change_id,name:this.change_className,coverPath:`course/${this.change_className}/${this.file_coursePic_name}`})
                                .then(response => {
                                    if(response.data.code===1){
                                        ElMessage({
                                            message: '信息修改成功',
                                            type: 'success',
                                        })
                                        this.getPageMessage(this.currentPage)
                                        this.dialogTableVisible = false
                                    }
                                })
                                .catch(() => {
                                    ElMessage.error({
                                        message: '信息修改失败',
                                    })
                                });
                            }).catch(() => {
                                ElMessage.error({
                                    message: '文件上传失败',
                                })
                            });
                        } catch (err) {
                            ElMessage.error({
                                message: '上行至储存桶发送异常',
                            })
                        }
                    })
                    .catch(() => {
                        ElMessage.error({
                            message: 'oss凭证获取失败，请重新上传',
                        })
                    });
                }
            }else if(this.or_addFile_changeClass==='add'){
                axios.get(AXIOS_URL+'/common/oss/getTempToken')
                    .then(response => {
                        const client = new OSS({
                            region: 'oss-cn-hangzhou',
                            accessKeyId: response.data.data.accessKeyId,
                            accessKeySecret: response.data.data.accessKeySecret,
                            stsToken: response.data.data.securityToken,
                            bucket: 'clsp',
                            secure: true,
                        })
                        try {
                            const options = {
                                meta: { temp: "demo" },
                                mime: "json",
                                headers: { "Content-Type": "application/json" },
                            };
                            // 上传文件
                            const loadingMessage = ElMessage({
                                message: '上传中...',
                                type: 'info',
                            });
                            client.put(`course/${this.addFile_classname}/${this.file_coursePic_name}`, this.file_coursePic_file, options)
                            .then(() => {
                                loadingMessage.close();
                                axios.post(AXIOS_URL+'/admin/course/file/add',{id:this.addFile_classid,filename:this.addFile_filename,filePath:`course/${this.addFile_classname}/${this.file_coursePic_name}`})
                                .then(response => {
                                    if(response.data.code===1){
                                        ElMessage({
                                            message: '资源上传成功',
                                            type: 'success',
                                        })
                                        this.searchPage_coursefile(this.addFile_classid)
                                        this.dialogTableVisible = false
                                    }
                                })
                                .catch((err) => {
                                    ElMessage.error({
                                        message: `资源上传失败${err.msg}`,
                                    })
                                });
                            }).catch(() => {
                                ElMessage.error({
                                    message: '文件上传失败',
                                })
                            });
                        } catch (err) {
                            ElMessage.error({
                                message: '上行至储存桶发送异常',
                            })
                        }
                    })
                    .catch(() => {
                        ElMessage.error({
                            message: 'oss凭证获取失败，请重新上传',
                        })
                    });
            }
        },
        moveFile(scope){
            axios.delete(`${AXIOS_URL}/admin/course/file/delete`, {
                    data: { id: scope.row.id },
                    headers: {
                        'Content-Type': 'application/json' // 可选：设置请求的内容类型
                    }
                })
                .then(response => {
                    // 处理成功响应
                    if(response.data.code===1){
                        ElMessage({
                            message: '资源删除成功',
                            type: 'success',
                        })
                        this.searchPage_coursefile(scope.row.id)
                    }else{
                        ElMessage.error({
                            message: response.data.msg,
                        })
                    }
                })
                .catch(() => {
                    // 处理错误
                    ElMessage.error({
                        message: '资源删除失败',
                    })
                });
        },
        searchByName(){
            this.dataWillShow = this.dataWillShow_org.filter(item => item.name.includes(this.searchName))
        },
        reMessage(scope){
            this.change_id = scope.row.id
            this.change_className = scope.row.name
            this.change_classPicUrl = scope.row.coverUrl.replace('https://bucket.clsp.folome.com.cn/','')
            this.dialogTableVisible = true
            this.or_addFile_changeClass = 'change'
        }
    }
}
</script>
<style scoped>
.el-button--primary{
    --el-button-bg-color:#ffecec;
}
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px 15px 50px 15px;
    height: auto;
}
.messageBox{
    margin: 25px 60px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 15px;
}
.option-input {
  width: 100%;
  margin-bottom: 8px;
}
.el-tag{
    margin-right: 5px;
}
</style>