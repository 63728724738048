<template>
    <div class="cardBox">
        <div class="messageBox">
        <h3>课程中心</h3>
            <el-card
                style="box-shadow: none; display: inline-block;"
                class="card"
                v-for="(everyClass) in classList"
                :key="everyClass.id"
                @click="sendData(everyClass.id)"
            >
                <img
                    :src="everyClass.coverUrl"
                    class="image"
                />
                <div class="classMessageBox">
                    <div class="courseName">{{ everyClass.name }}</div>
                    <div class="teachers" v-for="teacher in everyClass.teachers" :key="teacher">{{ teacher + " " }}</div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { AXIOS_URL } from '../constants.js'

export default {
    data(){
        return{
            classList:[]
        }
    },
    mounted(){
        this.getClassList()
    },
    methods:{
        sendData(id) {
            // 触发名为 'sendDataToParent' 的自定义事件，并传递数据 'hello'
            this.$emit('sendClickId', id);
        },
        getClassList(){
            axios.post(AXIOS_URL+'/student/course/pageSearch',{page:1,size:10})
            .then(response => {
                // 处理成功响应
                this.classList = response.data.data.records
                var unum = 2
                while(unum<=response.data.data.pages){
                    axios.post(AXIOS_URL+'/student/course/pageSearch',{page:unum,size:10})
                    .then(res => {
                        this.classList = this.classList.concat(res.data.data.records)
                    })
                    unum+=1
                }
                // this.dataWillShow_org = this.dataWillShow
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '获取数据失败',
                })
            });
        }
    }
}
</script>

<style scoped>
.cardBox{
    min-width: 800px;
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
}
.card{
    width: 270px;
    border: none;
    cursor: pointer;
}
.card:hover .courseName{
    color: #e72b47;
}
.image{
    object-fit: cover;
    height: 130px;
    width: 240px;
    border-radius: 5px;
}
.classMessageBox{
    padding: 0 5px;
    margin-bottom: -18px;
}
.courseName{
    transition: .3s;
    font-weight: 600;
    padding: 8px 0;
}
.teachers{
    font-size: 13px;
    color: #868686;
    padding: 2px 0;
}
.courseTask{
    font-size: 13px;
    color: #a1a1a1;
    padding: 2px 0;
}
.messageBox{
    margin: 25px 0 25px 60px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 15px;
}
</style>