<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
</style>
